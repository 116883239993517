import toastr from "toastr";
import axios from "axios";

const handleError = (error) => {
    if (error.response?.data?.message != null) {
        toastr.error(error.response?.data?.message);
    } else if (error.message != null) {
        toastr.error(error.message);
    } else {
        toastr.error("خطایی رخ داد!");
    }
    console.log(error);
};
const callServer = async (type, address, token, body) => {
    if (type === "post") {
        try {
            const { data } = await axios.post(address, body, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            return data;
        } catch (error) {
            handleError(error);
        }
    } else if (type === "get") {
        try {
            const { data } = await axios.get(address, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            return data;
        } catch (error) {
            handleError(error);
        }
    } else if (type === "get-notoken") {
        try {
            const { data } = await axios.get(address);
            return data;
        } catch (error) {
            handleError(error);
        }
    }
    return null;
};

export default callServer;
