import React, { Component } from "react";
import { connect } from "react-redux";
import { signOut, deselectProject, setProject } from "../../../actions";
import { Link } from "react-router-dom";

class DesktopHeader extends Component {
    state = { isAccountOpen: false, showNewPost: false, name: "", color: "", dueDate: new Date() };

    async componentDidMount() {}

    render() {
        window.onbeforeunload = function () {
            return "اطمینان دارید؟";
        };
        return (
            <>
                <header>
                    <div className="header">
                        <div className="align-items-center d-flex justify-content-between w-100 p-10">
                            <div className="header-logo">
                                <span>
                                    <img src="/Images/logo.png" alt="logo" />
                                </span>
                            </div>
                            <div className="search-parent">
                                <input placeholder="برای جستجو شروع به تایپ کنید ..." type="text" />
                                <i className="zmdi zmdi-search" />
                            </div>
                            <div className="header-btns d-flex">
                                <a href="#" className="icon-parent">
                                    <i className="zmdi zmdi-notifications" />
                                </a>

                                <div className="dropdown-menu">
                                    <span className="icon-parent" onClick={() => this.setState({ isAccountOpen: true })}>
                                        <i className="zmdi zmdi-account" />
                                    </span>
                                    {this.state.isAccountOpen ? (
                                        <div>
                                            <div
                                                className="auto-close z-index-10"
                                                onClick={() => this.setState({ isAccountOpen: false })}
                                            />
                                            <div className="dropdown-list">
                                                <Link className="d-block" to="/profile">
                                                    ویرایش پروفایل
                                                </Link>
                                                <Link className="d-block" to="/profile">
                                                    ویرایش رمزعبور
                                                </Link>
                                                <Link className="d-block" to="/Requests">
                                                    درخواست ها
                                                </Link>
                                                <span className="d-block" onClick={() => this.props.signOut()}>
                                                    خروج
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <Link className="floating-new-post" to="/panel/post/new">
                    <i className="iconly-brokenPaper-Plus"></i>
                    <span className="content d-inblock">
                        <span className="d-inblock m-l-10 p-l-10 p-r-10">پست جدید</span>
                    </span>
                </Link>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { user: state.auth.user, projectId: state.project.projectId, token: state.auth.token };
};
export default connect(mapStateToProps, { signOut, deselectProject, setProject })(DesktopHeader);
