import callServer from "../axios/callServer";
import * as moment from "jalali-moment";

export const notificationFunction = async function (dispatch, getState, token, projectId) {
    const date = await callServer("get", "/posts/today", token);
    if (!date) return;
    let now = new Date(date);
    let time = moment(now);
    const data = await callServer("post", "/Participates", token, {
        projectId: projectId,
        endDate: time.endOf("jMonth"),
    });
    if (!data) return;
    dispatch({
        type: "SET_NOTIFICATION",
        payload: data,
    });
};
