import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../Elements/Loading";
import TopMenu from "./../Layout/TopMenu";
import DropDown from "./../Elements/DropDown";
import ReactSelect from "../Elements/ReactSelect";
import HorizontalTaskManagement from "./../PageParts/Post/HorizontalTaskManagement";
import toastr from "toastr";
import FileUploader from "../Elements/FileUploader/FileUploader";
import callServer from "./../../axios/callServer";
import * as moment from "jalali-moment";
import DateTimePicker from "./../Elements/DateTimePicker/DateTimePicker";

class NewPost extends Component {
    async componentDidMount() {
        const projects = await callServer("get", "/projects", this.props.token);
        if (projects == null) return;
        const postStatuses = await callServer("get", "/posts/statuses", this.props.token);
        if (postStatuses == null) return;
        this.setState({
            projects: projects.map((el) => {
                return { value: el.id, label: el.name };
            }),
            postStatuses: postStatuses.map((el) => {
                return { value: el.id, label: el.name };
            }),
        });
        this.setState({ loading: false });
    }

    handleNewPost = async () => {
        if (this.state.name.length < 1 || this.state.projectId == null) return toastr.error("پروژه و نام الزامی است!");
        const newPost = {
            name: this.state.name,
            statusId: this.state.postStatusId,
            dueDate: this.state.dueDate,
            socialNetworkId: this.state.socialNetworkId,
            projectId: this.state.projectId,
            description: this.state.description,
            postTags: this.state.tags.map((t) => {
                return {
                    tag: {
                        title: t,
                    },
                };
            }),
            mediaCollections: this.state.urls.map((u) => {
                return {
                    url: u,
                };
            }),
            participates: [
                ...this.state.followers.map((el) => {
                    return {
                        userId: el.id,
                        participateTypeId: 3,
                    };
                }),
                ...this.state.mediaEditors.map((el) => {
                    return {
                        userId: el.id,
                        participateTypeId: 2,
                    };
                }),
                ...this.state.descriptionEditors.map((el) => {
                    return {
                        userId: el.id,
                        participateTypeId: 1,
                    };
                }),
            ],
        };
        // var participateTypeId = requestChangePost.ParticipateType == "Follower" ? 3 :
        // requestChangePost.ParticipateType == "DescriptionEditor" ? 1 : 2;

        const result = await callServer("post", "/Posts/New", this.props.token, newPost);
        this.props.history.push("/panel");
        if (result == null) return;
        console.log(result);
    };

    state = {
        loading: true,
        name: "",
        description: "",
        postProjectId: null,
        dueDate: new Date().setDate(new Date().getDate() + 1),
        socialNetworkId: null,
        postStatusId: null,
        projectId: null,
        projects: null,
        postStatuses: [],
        socialMedias: [],
        projectUsers: [],
        descriptionEditors: [],
        mediaEditors: [],
        followers: [],
        tags: [],
        urls: [],
    };
    render() {
        if (this.state.loading) return <Loading />;
        return (
            <div className="main-content pos-rel">
                <div className="m-x-40">
                    <div className="container">
                        <TopMenu hasBackButton={true} />
                        <div className="back-smoke p-y-50 position-relative row">
                            <div className="lg-3 order-lg-0 order-1 post-sidebar">
                                <div className="people-in">
                                    <HorizontalTaskManagement
                                        title="افراد حاضر در پروژه"
                                        isProjectSelected={this.state.projectUsers.length > 0}
                                        users={this.state.projectUsers}
                                        currentUsers={this.state.followers}
                                        currentUsersChange={(users) => {
                                            this.setState({ followers: users });
                                        }}
                                        type="Follower"
                                        titleClassName="f-16"
                                    />
                                </div>
                                {/* <div className="notif-setting">
                                    <div className="heading">
                                        <span>تنظیم نوتیف</span>
                                    </div>
                                    <div className="notif">
                                        <div className="notif-service">
                                            <label className="cutsom-checkbox-1">
                                                App
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>

                                            <label className="cutsom-checkbox-1">
                                                Email
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>

                                            <label className="cutsom-checkbox-1">
                                                SMS
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="notif-date m-t-10">
                                            <div className="day">
                                                <span>
                                                    <label for="notif-day">روز</label>
                                                    <input id="notif-day" />
                                                </span>
                                            </div>
                                            <div className="time">
                                                <span>
                                                    <label for="notif-time">ساعت</label>
                                                    <input id="notif-time" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="notif-btn m-t-10">
                                            <a>انتشار</a>
                                        </div>
                                        <div className="notif-status m-t-10">
                                            <span>چهارشنبه</span>
                                            <p>
                                                ساعت
                                                <span>12</span>
                                            </p>
                                            <p>
                                                App
                                                <span>Notification</span>
                                            </p>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="publish-segment">
                                    <p>
                                        <span>تاریخ انتشار</span>
                                        <span>{moment(this.state.dueDate).format("jYYYY/jM/jD")}</span>
                                    </p>
                                    {/* <p>
                                        <span>تاریخ منتشر شده</span>
                                        <span>1400/02/05</span>
                                    </p> */}
                                </div>
                                {/* <div className="confirmation">
                                    <div className="heading">
                                        <span>تاییدیه ها</span>
                                    </div>
                                    <div className="people-to-confirm">
                                        <label className="cutsom-checkbox-1">
                                            اشکان
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="cutsom-checkbox-1">
                                            محسن
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div> */}
                            </div>
                            <div className="lg-9 order-lg-1 order-2 post-overview">
                                <div className="post-desc">
                                    <div className="row">
                                        <div className="lg-4 p-b-15">
                                            <label htmlFor="PostTitle">عنوان پست</label>
                                            <input
                                                value={this.state.name}
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                                className="input f-16 m-t-5"
                                                id="PostTitle"
                                            />
                                        </div>
                                        <div className="lg-8 p-b-15">
                                            <label htmlFor="ProjectTitle">پروژه</label>
                                            <ReactSelect
                                                onChange={async (item) => {
                                                    this.setState({
                                                        projectId: item.value,
                                                        socialNetworkId: null,
                                                        socialMedias: [],
                                                        projectUsers: [],
                                                        descriptionEditors: [],
                                                        mediaEditors: [],
                                                        followers: [],
                                                    });

                                                    const projectSocialMedias = await callServer(
                                                        "get",
                                                        `/projects/socialMedias/${item.value}`,
                                                        this.props.token
                                                    );
                                                    if (projectSocialMedias == null) return;
                                                    const projectUsers = await callServer(
                                                        "get",
                                                        `/projects/Users/${item.value}`,
                                                        this.props.token
                                                    );
                                                    if (projectUsers == null) return;
                                                    console.log(projectUsers);
                                                    this.setState({
                                                        socialMedias: projectSocialMedias.map((el) => {
                                                            return { value: el.id, label: el.name };
                                                        }),
                                                        projectUsers: projectUsers,
                                                    });
                                                }}
                                                options={this.state.projects}
                                                className="m-t-5"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="lg-4">
                                            <label htmlFor="PostDate">تاریخ پست</label>
                                            <div>
                                                <DateTimePicker
                                                    inputClass="input text-center m-t-5 f-16"
                                                    value={this.state.dueDate}
                                                    onChange={(value) => {
                                                        this.setState({ dueDate: value.toDate() });
                                                        console.log(value.toDate());
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="lg-4">
                                            <label htmlFor="PostPlatform">پلتفرم</label>
                                            <ReactSelect
                                                onChange={(item) => {
                                                    this.setState({ socialNetworkId: item.value });
                                                }}
                                                options={this.state.socialMedias}
                                                value={this.state.socialNetworkId}
                                                noOptionsMessage="ابتدا یک پروژه انتخاب کنید"
                                                className="m-t-5"
                                            />
                                        </div>
                                        <div className="lg-4">
                                            <label htmlFor="PostStatus">وضعیت پست</label>
                                            <ReactSelect
                                                options={this.state.postStatuses}
                                                value={this.state.postStatusId}
                                                onChange={(item) => {
                                                    this.setState({ postStatusId: item.value });
                                                }}
                                                className="m-t-5"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <DropDown name="متن پست" isOpen={false} className="p-0 post-text" titleClassName="text-bold">
                                    <div className="post-text-body row p-15 p-b-30">
                                        <div className="md-6">
                                            <p>متن پست</p>
                                            <textarea
                                                value={this.state.description}
                                                onChange={(e) => this.setState({ description: e.target.value })}
                                                className="w-100 m-b-20 textarea m-t-10"
                                            ></textarea>
                                        </div>
                                        <div className="md-6">
                                            <div className="responsible-people p-t-5 p-b-15">
                                                <HorizontalTaskManagement
                                                    title="افراد مسئول"
                                                    isProjectSelected={this.state.projectUsers.length > 0}
                                                    users={this.state.projectUsers}
                                                    currentUsers={this.state.descriptionEditors}
                                                    currentUsersChange={(users) => {
                                                        this.setState({ descriptionEditors: users });
                                                    }}
                                                    type="Follower"
                                                    titleClassName="f-16"
                                                />
                                            </div>
                                            <div className="">
                                                <label>هشتگ و تگ</label>
                                                <ReactSelect
                                                    onChange={(items) => {
                                                        this.setState({ tags: items.map((i) => i.value) });
                                                    }}
                                                    isCreatable
                                                    isMulti
                                                    className="m-t-5"
                                                />
                                            </div>
                                            {/* <div className="comments m-t-20">
                                                <p>نظرات</p>
                                                <div className="comments-parent">
                                                    <div className="comments-child">
                                                        <span className="m-l-10">
                                                            <img src="account.png" />
                                                        </span>
                                                        <p>
                                                            این چیه که آوردین این چیه که آوردین این چیه که آوردین این چیه که آوردین این
                                                            چیه که آوردین این چیه که آوردین این چیه که آوردین این چیه که آوردین این چیه
                                                            که آوردین این چیه که آوردین این چیه که آوردین این چیه که آوردین این چیه که
                                                            آوردین این چیه که آوردین این چیه که آوردین
                                                        </p>
                                                    </div>
                                                    <div className="comments-child">
                                                        <span className="m-l-10">
                                                            <img src="account.png" />
                                                        </span>
                                                        <p>
                                                            این چیه که آوردین این چیه که آوردین این چیه که آوردین این چیه که آوردین این
                                                            چیه که آوردین این چیه که آوردین این چیه که آوردین این چیه که آوردین این چیه
                                                            که آوردین این چیه که آوردین این چیه که آوردین این چیه که آوردین این چیه که
                                                            آوردین این چیه که آوردین این چیه که آوردین
                                                        </p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </DropDown>

                                <DropDown name="مدیا تصویری" isOpen={false} className="p-0 post-media" titleClassName="text-bold">
                                    <div className="p-15 p-b-30">
                                        <div className="row">
                                            <div className="lg-8">
                                                <div className="horizontal-filepond">
                                                    <FileUploader
                                                        allowMultiple
                                                        name="file"
                                                        server={{
                                                            process: {
                                                                url: `/ImageUploader`,
                                                                headers: {
                                                                    Authorization: `Bearer ${this.props.token}`,
                                                                },
                                                                onload: (par) => {
                                                                    par = JSON.parse(par);
                                                                    if (par.imageUrl) {
                                                                        this.setState({
                                                                            urls: [...this.state.urls, par.imageUrl],
                                                                        });
                                                                    }
                                                                    return par.imageUrl;
                                                                },
                                                            },
                                                            revert: async (par, load, a, b, c) => {
                                                                try {
                                                                    // remove
                                                                    this.setState({
                                                                        urls: this.state.urls.filter((u) => u != par),
                                                                    });
                                                                    load();
                                                                } catch {
                                                                    toastr.error("خطا در برقراری ارتباط با سرور");
                                                                }
                                                            },
                                                            remove: async (par, load) => {
                                                                try {
                                                                    //remove
                                                                    console.log(par);
                                                                    load();
                                                                } catch {
                                                                    toastr.error("خطا در برقراری ارتباط با سرور");
                                                                }
                                                            },
                                                            load: async (par, load) => {
                                                                const response = await fetch(par);
                                                                const fileName = [...response.url.split("/")].pop();
                                                                const blob = await response.blob();
                                                                const file = new File([blob], fileName, { type: blob.type });
                                                                console.log(file);

                                                                load(file);
                                                            },
                                                        }}
                                                        files={this.state.files}
                                                        onChange={({ files }) => {
                                                            this.setState({ files });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="lg-4">
                                                <div className="responsible-people p-t-5 p-b-15">
                                                    <HorizontalTaskManagement
                                                        title="افراد مسئول"
                                                        isProjectSelected={this.state.projectUsers.length > 0}
                                                        users={this.state.projectUsers}
                                                        currentUsers={this.state.mediaEditors}
                                                        currentUsersChange={(users) => {
                                                            this.setState({ mediaEditors: users });
                                                        }}
                                                        type="Follower"
                                                        titleClassName="f-16"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DropDown>

                                {/* <div className="post-add">
                                    <a href="#">
                                        <i className="zmdi zmdi-plus"></i>
                                    </a>
                                </div> */}
                                <div className="submit-archieve">
                                    <a onClick={this.handleNewPost} className="submit-btn">
                                        ثبت
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token, projectId: state.project.projectId };
};
export default connect(mapStateToProps)(NewPost);
