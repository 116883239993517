import React from "react";
import { Link } from "react-router-dom";
import "../Style/Login/Login.scss";
import { connect } from "react-redux";
import { signIn, signOut } from "../../actions";
import SimpleReactValidator from "simple-react-validator";

class Login extends React.Component {
    state = { email: "", password: "", loading: false };

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
    }

    render() {
        return (
            <div className="new-design">
                <div className="login-section-new">
                    <div className="container">
                        <div className="screen p-x-15">
                            <div className="row">
                                <div className="lg-5">
                                    <div className="screen__content">
                                        <form className="login" onSubmit={(e) => e.preventDefault()}>
                                            <div className="p-b-10 p-t-20">
                                                <img className="login-logo" src="/images/logo.png" />
                                            </div>
                                            <div className="p-y-10 text-center">لطفا مشخصات خود را وارد کنید</div>
                                            <div className="login__field p-y-10">
                                                <input
                                                    value={this.state.email}
                                                    onChange={(event) => this.setState({ email: event.target.value })}
                                                    type="text"
                                                    className="w-100 input"
                                                    placeholder="ایمیل"
                                                />
                                            </div>
                                            <div className="login__field p-y-10">
                                                <input
                                                    value={this.state.password}
                                                    onChange={(event) => this.setState({ password: event.target.value })}
                                                    type="password"
                                                    className="w-100 input"
                                                    placeholder="رمزعبور"
                                                />
                                            </div>
                                            <div className="d-flex ai-center jc-center">
                                                {this.state.loading ? (
                                                    <button className="button login__submit loading-btn">
                                                        <span className="button__text">صبر کنید ...</span>
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="button login__submit"
                                                        onClick={async () => {
                                                            this.setState({ loading: true });
                                                            await this.props.signIn(this.state.email, this.state.password);
                                                            this.setState({ loading: false });
                                                        }}
                                                    >
                                                        <span className="button__text">ورود</span>
                                                    </button>
                                                )}
                                            </div>
                                            <div className="p-t-15">
                                                <Link className="p-b-30 p-t-5 f-12 d-block p-r-10 black text-center" to="ForgetPassword">
                                                    <span className="white d-inblock p-r-5 default-color f-14">
                                                        رمز خود را فراموش کرده‌اید؟
                                                    </span>
                                                </Link>
                                            </div>
                                            <Link className="p-b-30 p-t-5 f-12 d-block p-r-10 black text-center" to="Register">
                                                کاربر جدید هستید؟
                                                <span className="white d-inblock p-r-5 default-color f-14">ثبت نام</span>
                                            </Link>
                                        </form>
                                        <div className="social-login">
                                            {/* <Link className="p-t-15 f-14 d-block default-color" to="Register">
                                                <span className="white d-inblock f-16">ثبت نام </span>
                                            </Link>
                                            <span className="white d-inblock f-16">فراموشی رمزعبور </span> */}
                                            {/* <div className="social-icons">
                                    <a className="social-login__icon fab fa-instagram">
                                        <span></span>
                                    </a>
                                    <a href="#" className="social-login__icon fab fa-facebook"></a>
                                    <a href="#" className="social-login__icon fab fa-twitter"></a>
                                </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="lg-7 p-0 d-flex">
                                    <div className="login-bg-img d-flex ai-center">
                                        <img src="/images/login.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, { signIn, signOut })(Login);
