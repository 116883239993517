import React from "react";
import { connect } from "react-redux";
import Loading from "./../Elements/Loading";
import PageRender from "./../PageParts/ProjectSetting/PageRender";
import callServer from "./../../axios/callServer";

class ProjectSetting extends React.Component {
    async componentDidMount() {
        const project = await callServer("get", `/projects/${this.props.match.params.id}`, this.props.token);
        if (project == null) return;
        console.log(project);
        this.setState({ project });
    }
    state = { project: null };
    render() {
        if (this.state.project != null) return <PageRender project={this.state.project} />;
        else return <Loading />;
    }
}

const mapStateToProps = (state) => {
    return { token: state.auth.token };
};
export default connect(mapStateToProps)(ProjectSetting);
