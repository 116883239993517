import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class TopMenu extends Component {
    state = {
        isProjectSettingsOpen: false,
    };

    render() {
        return (
            <div className="d-flex justify-content-between p-t-30">
                <div className="navigation pos-st-im">
                    <Link to="/panel" className="p-0">
                        <span className={`align-items-center d-flex pointer ${this.props.isDashboard ?? false ? "active" : ""}`}>
                            <i className="zmdi zmdi-tv-list f-2 m-l-5"></i>داشبورد
                        </span>
                    </Link>
                    <Link to="/panel/Calendar" className="p-0">
                        <span className={`align-items-center d-flex pointer ${this.props.isCalendar ?? false ? "active" : ""}`}>
                            <i className="zmdi zmdi-calendar f-2 m-l-5"></i>تقویم
                        </span>
                    </Link>
                    {this.props.projectId != null ? (
                        <div
                            className="p-0 pos-rel"
                            onClick={() => this.setState({ isProjectSettingsOpen: !this.state.isProjectSettingsOpen })}
                        >
                            <span className={`align-items-center d-flex pointer ${this.state.isProjectSettingsOpen ? "active" : ""}`}>
                                <i className="zmdi zmdi-settings f-2 m-l-5"></i>تنظیمات پروژه
                            </span>
                            {this.state.isProjectSettingsOpen ? (
                                <div className="pos-rel">
                                    <div
                                        className="auto-close z-index-1"
                                        onClick={() => this.setState({ isProjectSettingsOpen: !this.state.isProjectSettingsOpen })}
                                    ></div>
                                    <div className="top-menu-sub z-index-2 f-14">
                                        <Link to={`/panel/project/SocialMedia/${this.props.projectId}`} className="p-0">
                                            <span className={`align-items-center jc-center d-flex pointer`}>
                                                <i className="f-18 m-l-10 m-l-5 zmdi zmdi-view-module"></i>شبکه اجتماعی
                                            </span>
                                        </Link>
                                        <Link to={`/panel/Project/setting/${this.props.projectId}`} className="p-0">
                                            <span className={`align-items-center jc-center d-flex pointer`}>
                                                <i className="f-18 m-l-10 m-l-5 zmdi zmdi-assignment"></i>ویرایش پروژه
                                            </span>
                                        </Link>
                                        <Link to={`/panel/Project/Users/${this.props.projectId}`} className="p-0">
                                            <span className={`align-items-center jc-center d-flex pointer`}>
                                                <i className="f-18 m-l-10 m-l-5 zmdi zmdi-accounts"></i>اعضای پروژه
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
                <div
                    onClick={() => window.history.back()}
                    className={`align-items-center d-flex pointer ${this.props.hasBackButton ?? false ? "" : "d-none-im"}`}
                >
                    <span className="f-2 p-l-25">
                        <i className="zmdi zmdi-arrow-left"></i>
                    </span>
                </div>
            </div>
        );
    }
}

export default TopMenu;
