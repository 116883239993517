import React from "react";
import Select from "../../Elements/Select";
import Button from "../../Elements/Button";
import toastr from "toastr";
import callServer from "../../../axios/callServer";
import {connect} from "react-redux";
import {deselectProject} from "../../../actions";
import {withRouter} from "react-router-dom";
import Loading from "../../Elements/Loading";

class EditUser extends React.Component {
    state = {
        loading: false,
        accessTypes: this.props.accessTypes,
        user: this.props.user,
    }

    async changeUserAccessType(accessTypeId, userId, lastValue) {
        this.setState({loading: true})
        const data = await callServer("post", `/Projects/Users/ChangeAccess`, this.props.token, {
            accessTypeId,
            userId,
            projectId: this.props.projectId,
        })
        if (data == null) {
            this.setState({loading: false, user: {...this.state.user, accessTypeId: lastValue}});
            return;
        }
        this.setState({loading: false, user: {...this.state.user, accessTypeId: accessTypeId}});
        this.props.setState({users: data});
        toastr.success("دسترسی تغییر یافت");
    }

    async RemoveUser(userId) {
        let adminUsers = this.props.users.filter((user) => user.accessTypeId === 1);
        let userType = this.props.users.filter((user) => user.id === userId)[0].accessTypeId;
        if (adminUsers.length < 2 && userType === 1) {
            toastr.warning("پروژه باید حداقل یک مدیر داشته باشد!");
            return;
        }
        const data = await callServer("post", `/Projects/Users/RemoveAccess`, this.props.token, {
            userId,
            projectId: this.props.projectId,
        });
        if (data == null) return;
        if (userId === this.props.currentUserId) {
            this.props.history.push("/panel");
        } else {
            this.props.setState({users: data});
            toastr.success("کاربر حذف شد!");
        }
    }

    render() {
        const u = this.state.user;
        if (this.state.loading) return (
            <div key={u.id} className="lg-4 md-6">
                <div className="box full-border pos-rel" style={{border: "1px solid black"}}>
                    <div className="p-y-50">
                        <Loading/>
                    </div>
                </div>
            </div>
        );
        return (
            <div key={u.id} className="lg-4 md-6">
                <div className="box full-border" style={{border: "1px solid black"}}>
                    <div className="d-flex ai-center p-y-10">
                        <img
                            className="project-logo big-image"
                            alt={u.imageAlt}
                            src={u.imageUrl == null ? "/Images/profile.png" : u.imageUrl}
                        />
                        <div className="f-14 p-r-10">
                            <p className="p-b-5">
                                {u.firstName} {u.lastName}
                            </p>
                            <p>{u.email}</p>
                        </div>
                    </div>
                    <div className="d-flex ai-center jc-between p-t-15">
                        {this.props.currentUserEmail === u.email ? (
                            <div className="min-h-1px"></div>
                        ) : (
                            <Select
                                selectedOptionId={u.accessTypeId}
                                selectOptions={this.state.accessTypes}
                                selectChange={(id, lastValue) => this.changeUserAccessType(id, u.id, lastValue)}
                            >
                                دسترسی
                            </Select>
                        )}
                        {this.props.users.length > 1 ? (
                            <div onClick={(e) => this.RemoveUser(u.id)}>
                                <Button>
                                    {this.props.currentUserEmail === u.email ? (
                                        <span className="ai-center d-flex" style={{paddingBottom: "2px"}}>
                                                <span className="iconly-brokenLogout p-l-5"></span>
                                                خروج
                                            </span>
                                    ) : (
                                        <span>
                                                <span className="iconly-brokenDelete p-l-5"></span>
                                                حذف
                                            </span>
                                    )}
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const
    mapStateToProps = (state) => {
        return {
            token: state.auth.token,
            currentUserEmail: state.auth.user.email,
            currentUserId: state.auth.user.id,
        };
    };
export default connect(mapStateToProps, {deselectProject})

(
    withRouter(EditUser)
)
;
