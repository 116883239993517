import React, { Component } from "react";
import { connect } from "react-redux";

class DropDown extends Component {
    async componentDidMount() {}
    state = { isOpen: this.props.isOpen ?? false };
    render() {
        return (
            <div className={`default-ddr ${this.props.className}`}>
                <div className="d-flex align-items-center p-15 pointer" onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                    <span className={`zmdi-chevron-${this.state.isOpen ? "down" : "left"} zmdi p-l-10 f-22`}></span>
                    <span className={this.props.titleClassName ?? ""}>{this.props.name}</span>
                </div>
                {this.state.isOpen ? <div>{this.props.children}</div> : null}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token };
};
export default connect(mapStateToProps)(DropDown);
