import React from "react";

class Button extends React.Component {
    render() {
        return <span onClick={this.props.onClick}
                     className={`btn f-12 ${this.props.className}`}>{this.props.children}</span>;
    }
}

export default Button;
