import React from "react";
import {connect} from "react-redux";
import {deselectProject} from "../../actions";
import SimpleReactValidator from "simple-react-validator";
import Loading from "../Elements/Loading";
import TopMenu from "./../Layout/TopMenu";
import EditUser from "../PageParts/ProjectUsers/EditUser";
import AddNewUser from "../PageParts/ProjectUsers/AddNewUser";
import callServer from "../../axios/callServer";

class ProjectUsers extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
    }

    async componentDidMount() {
        const accessTypes = await callServer("get", `/Projects/AccessTypes/`, this.props.token);
        if (accessTypes == null) return;
        const data = await callServer("get", `/Projects/Users/${this.state.projectId}`, this.props.token);
        if (data == null) return;
        this.setState({users: data, accessTypes: accessTypes});
    }

    state = {users: [], accessTypes: [], projectId: this.props.match.params.id};


    showUsers() {
        return this.state.users.map((u) => {
            return (
                <EditUser projectId={this.state.projectId} users={this.state.users} accessTypes={this.state.accessTypes}
                          currentUserEmail={this.props.currentUserEmail} user={u}
                          setState={s => this.setState(s)}/>
            );
        });
    }

    render() {


        if (this.state.users.length > 0)
            return (
                <div className="main-content black-filepond pos-rel">
                    <div className="m-x-40">
                        <TopMenu hasBackButton={true} projectId={this.state.projectId}/>
                        <div className="back-smoke">
                            <div className="row jc-center p-t-30 p-b-100 m-b-100 ai-center p-x-15">
                                <div className="lg-10">
                                    <div className="box main-box-header pos-rel">
                                        <h4 className="f-22 p-y-15 text-center">کاربران پروژه</h4>
                                        <div className="row p-t-10 ai-center jc-center">
                                            {this.showUsers()}
                                            <AddNewUser projectId={this.state.projectId} users={this.state.users}
                                                        accessTypes={this.state.accessTypes}
                                                        setState={(s) => this.setState(s)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        else return <Loading/>;
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        currentUserEmail: state.auth.user.email,
        currentUserId: state.auth.user.id,
    };
};
export default connect(mapStateToProps, {deselectProject})(ProjectUsers);
