import React, { Component } from "react";
import { connect } from "react-redux";
import PostItem from "../Common/PostItem";
import Loading from "../../Elements/Loading";
import callServer from "../../../axios/callServer";

class DateProject extends Component {
    async componentDidMount() {
        this.setState({ loading: true });
        const result = await callServer("get", `/Posts/ArchivedPosts/${this.props.projectId}`, this.props.token);
        this.setState({ loading: false });
        if (result == null) return;
        this.setState({ posts: result });
    }

    state = { posts: [], loading: false };

    render() {
        if (this.state.loading) {
            return (
                <div className="container">
                    <div className="back-smoke p-y-50 position-relative row">
                        <Loading />
                    </div>
                </div>
            );
        }
        if (this.state.posts.length === 0) {
            return (
                <div className="container">
                    <div className="back-smoke p-y-50 ai-center jc-center position-relative row">
                        <div className="lg-3">موردی وجود ندارد</div>
                    </div>
                </div>
            );
        }
        return (
            <div className="container">
                <div className="back-smoke p-y-50 position-relative row">
                    {this.state.posts?.map((p) => (
                        <div className="lg-3">
                            <PostItem post={p} dontShowFullPost />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { token: state.auth.token };
};
export default connect(mapStateToProps)(DateProject);
