/* eslint-disable import/no-anonymous-default-export */
import cookie from "react-cookies";

const INITIAL_STATE = {
    token: cookie.load("userToken"),
    user: cookie.load("user"),
};
export default (state = INITIAL_STATE, action) => {
    var expDate = new Date();
    expDate.setDate(expDate.getDate() + 1);
    switch (action.type) {
        case "UPDATE_USER":
            cookie.remove("user", { path: "/" });
            cookie.save("user", action.payload, { expires: expDate, path: "/" });
            return { ...state, user: action.payload };
        case "SIGN_IN":
            cookie.save("userToken", action.payload.token, { expires: expDate, path: "/" });
            cookie.save("user", action.payload, { expires: expDate, path: "/" });
            return { ...state, token: action.payload.token, user: action.payload };
        case "SIGN_OUT":
            cookie.remove("userToken", { path: "/" });
            cookie.remove("user", { path: "/" });
            return { ...state, token: null };
        default:
            return state;
    }
};
