import React from "react";
import moment from "jalali-moment";
import UserImage from "./../Common/UserImage";

class CommentItem extends React.Component {
    render() {
        console.log(this.props.comment);
        const comment = this.props.comment.comment;
        const user = this.props.comment.user;
        let time = moment(this.props.comment?.time);
        return (
            <div className="comments-child">
                <div className="m-l-10 image">
                    <UserImage className="m-0-im" user={user} />
                </div>
                <div className="comment-content d-flex m-t-5">
                    {user != null ? (
                        <div
                            className="p-l-5"
                            style={{ minWidth: ((user?.firstName.length ?? 0) + (user?.lastName.length ?? 0)) * 10 + "px" }}
                        >
                            {user?.firstName} {user?.lastName} :
                        </div>
                    ) : null}
                    <div>{comment?.content}</div>
                    <div className="comment-date">{time.calendar()}</div>
                </div>
            </div>
        );
    }
}

export default CommentItem;
