import React, { Component } from "react";
import TopMenu from "../Layout/TopMenu";
import NewCalendar from "../PageParts/Calendar/NewCalendar";

class Calendar extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="container">
                    <TopMenu isCalendar={true} />

                    <div className="back-smoke p-5 position-relative">
                        <NewCalendar />
                    </div>
                </div>
            </div>
        );
    }
}
export default Calendar;
