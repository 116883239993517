import React, { Component } from "react";
import { connect } from "react-redux";
import { orderByDate } from "../../../functions";
import DropDown from "../../Elements/DropDown";
import Loading from "../../Elements/Loading";
import callServer from "./../../../axios/callServer";
import PostItem from "./../Common/PostItem";

class DateProject extends Component {
    async componentDidMount() {
        let socialNetwork = await callServer("get", `/projects/socialMedias/${this.props.projectId}`, this.props.token);
        if (socialNetwork == null) return;
        socialNetwork = [...socialNetwork, { id: null, name: "بدون پلتفرم" }];
        this.setState({ socialNetwork });
        let posts = this.props.posts;
        socialNetwork.forEach((sn) => {
            let snPosts = posts.filter((p) => p.socialNetworkId == sn.id);
            snPosts = orderByDate(snPosts);
            this.setState({ socialNetworkPosts: [...this.state.socialNetworkPosts, snPosts] });
        });
        this.setState({ loading: false });
    }
    state = { loading: true, socialNetwork: [], socialNetworkPosts: [] };
    render() {
        if (this.state.loading)
            return (
                <div className="pos-rel m-y-100 p-y-100">
                    <Loading />
                </div>
            );
        return (
            <div className="container">
                <div className="back-smoke p-y-50 position-relative ">
                    {this.state.socialNetwork.map((p, index) => {
                        return (
                            <div className="p-b-15">
                                <DropDown name={p.name} isOpen={false}>
                                    <div className="container">
                                        <div className="p-y-50 position-relative row ai-start">
                                            {this.state.socialNetworkPosts[index].length > 0 ? (
                                                this.state.socialNetworkPosts[index]?.map((p) => {
                                                    if (p.statusId != 6) {
                                                        return (
                                                            <div className="lg-3">
                                                                <PostItem post={p} />
                                                            </div>
                                                        );
                                                    }
                                                })
                                            ) : (
                                                <div className="text-center bold default-color lg-12">پستی وجود ندارد!</div>
                                            )}
                                        </div>
                                    </div>
                                </DropDown>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token, project: state.project };
};
export default connect(mapStateToProps)(DateProject);
