import axios from "axios";
import toastr from "toastr";

export const authFunction = async function (dispatch, getState, email, password) {
    try {
        const user = await axios.post(
            "/User/Login",
            {
                email,
                password,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        dispatch({
            type: "SIGN_IN",
            payload: user.data,
        });
    } catch (err) {
        console.log(err);
        toastr.error(err.response?.data?.message);
        dispatch({
            type: "FAILED_SIGN_IN",
        });
    }
};

export const registerFunction = async function (dispatch, getState, userInputs) {
    try {
        const user = await axios.post(
            "/User/Register",
            {
                email: userInputs.email,
                password: userInputs.password,
                firstName: userInputs.firstName,
                lastName: userInputs.lastName,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        dispatch({
            type: "SIGN_IN",
            payload: user.data,
        });
    } catch (err) {
        console.log(err);
        toastr.error(err.response?.data?.message);
        dispatch({
            type: "FAILED_SIGN_IN",
        });
    }
};
