import React from "react";
import Loading from "./../Elements/Loading";
import axios from "axios";
import {connect} from "react-redux";
import TopMenu from "./../Layout/TopMenu";
import Button from "../Elements/Button";

class UserInvitations extends React.Component {
    state = {requests: null};

    async componentDidMount() {
        const {data} = await axios.get(`/Users/Requests`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.props.token}`,
            },
        });
        if (data == null) return;
        console.log(data);

        this.setState({requests: data});
    }

    rejectRequest = async (id) => {
        const {data} = await axios.get(`/Users/Request/Reject/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.props.token}`,
            },
        });
        if (data == null) return;

        this.componentDidMount();
    }

    acceptRequest = async (id) => {
        const {data} = await axios.get(`/Users/Request/Accept/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.props.token}`,
            },
        });
        if (data == null) return;

        this.componentDidMount();
    }

    showRequests = () => {
        return this.state.requests.map((r) => {
            return (
                <div className="lg-3 md-4 ">
                    <div className="box" style={{border: "1px solid black"}}>
                        <div className="d-flex ai-center">
                            پروژه :
                            {r.project.name}
                        </div>
                        <div className="d-flex p-y-10 ai-center">
                            درخواست دهنده :
                            {r.requestBy.firstName} {r.requestBy.lastName}
                        </div>
                        <div className="d-flex ai-center p-t-10 jc-between">
                            <Button onClick={() => this.rejectRequest(r.id)} className="btn-red">رد درخواست</Button>
                            <Button onClick={() => this.acceptRequest(r.id)} className="btn-green">قبول درخواست</Button>
                        </div>
                    </div>
                </div>
            );
        });
    };

    render() {
        if (this.state.requests != null && this.state.requests !== "") {
            return (
                <div className="main-content black-filepond pos-rel">
                    <div className="m-x-40">
                        <TopMenu hasBackButton={true}/>
                        <div className="back-smoke">
                            <div className="row jc-center p-t-30 p-b-100 m-b-100 ai-center p-x-15">
                                <div className="lg-10 box main-box-header pos-rel">
                                    <h4 className="f-22 p-y-15 text-center">درخواست ها</h4>
                                    <div className="row p-t-10 ai-center jc-center">
                                        {this.showRequests()}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Loading/>;
        }
    }
}

const mapStateToProps = (state) => {
    return {token: state.auth.token};
};
export default connect(mapStateToProps)(UserInvitations);
