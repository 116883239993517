import { authFunction, registerFunction } from "./authActionFunction";
import { notificationFunction } from "./notificationFuction";

export const signIn = (email, password) => (dispatch, getState) => {
    return authFunction(dispatch, getState, email, password);
};

export const register = (user) => (dispatch, getState) => {
    return registerFunction(dispatch, getState, user);
};

export const signOut = () => {
    return {
        type: "SIGN_OUT",
    };
};

export const updateUser = (user) => {
    return {
        type: "UPDATE_USER",
        payload: user,
    };
};

export const selectProject = (project) => {
    return {
        type: "SELECT_PROJECT",
        payload: project,
    };
};

export const deselectProject = () => {
    return {
        type: "DESELECT_PROJECT",
    };
};

export const setProject = (project) => {
    return {
        type: "SET_PROJECT",
        payload: project,
    };
};

export const setNotification = (token, projectId) => (dispatch, getState) => {
    return notificationFunction(dispatch, getState, token, projectId);
};
