import React, { Component } from "react";
import * as moment from "jalali-moment";
import { toLocalDate } from "../../../functions";

class ActivityItem extends Component {
    state = { showMore: false };
    buildSentence = (ac) => {
        switch (ac.activityType.id) {
            case 1:
                return "پست را ساخت";
            case 2:
                return "مدیا اضافه کرد";
            case 3:
                return "مدیا حذف کرد";
            case 4:
                return "دنبال کننده به پست اضافه کرد";
            case 5:
                return "یک نفر را به وظیفه متن اضافه کرد";
            case 6:
                return "یک نفر را به وظیفه مدیا اضافه کرد";
            case 7:
                return "یک نفر را از وظیفه مدیا حذف کرد";
            case 8:
                return "یک نفر را از وظیفه متن حذف کرد";
            case 9:
                return "یک دنبال کننده به پست اضافه کرد";
            case 10:
                return "شبکه اجتماعی پست را تغییر داد";
            case 11:
                return "متن را ویرایش کرد";
            case 12:
                return "رنگ را تغییر داد";
            case 13:
                return "وضعیت پروژه را تغییر داد";
            case 14:
                return "تاریخ پروژه راعوض کرد";
            case 15:
                return "یک نظر اضافه کرد";
            case 17:
                return "نام پروژه را عوض کرد";
            case 18:
                return "تگ های پروژه را تغییر داد";
            case 19:
                return "به تایید کنندگان اضافه کرد";
            case 20:
                return "پست را تایید کرد";
            case 21:
                return "تایید پست را لغو کرد";
            case 22:
                return "از تایید کنندگان کم کرد";
            default:
                return "پست را تغییر داد";
        }
    };

    render() {
        const ac = this.props.activity;
        return (
            <div>
                <div className="activity-item m-y-10 d-flex">
                    <div className="activity-user-image">
                        {ac.user?.imageUrl == null ? (
                            <div className="img">
                                {ac?.user?.firstName?.charAt(0)} {ac?.user?.lastName?.charAt(0)}
                            </div>
                        ) : (
                            <img className="img" src={ac.user?.logo} alt="" />
                        )}
                    </div>
                    <div className="activity-text d-flex jc-between grow-1">
                        <div>
                            {moment(new Date(ac.time))?.calendar()} ، {ac.user?.firstName} {ac.user?.lastName}{" "}
                            {ac.user?.firstName == null && ac.user?.lastName == null ? ac.user?.email : null} {this.buildSentence(ac)}
                        </div>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 13, 14, 15, 16, 17].filter((num) => num == ac.activityType.id).length >
                        0 ? null : (
                            <div
                                onClick={() => {
                                    this.setState({ showMore: !this.state.showMore });
                                }}
                                className="p-x-10 pointer"
                            >
                                {this.state.showMore ? "کمتر" : "بیشتر"}
                            </div>
                        )}
                    </div>
                </div>
                {this.state.showMore ? (
                    <div className="activity-detail">
                        <div className="d-flex ai-center jc-around p-b-5 default-color">
                            <div>قبل</div>
                            <div>بعد</div>
                        </div>
                        <div className="d-flex ai-center jc-around p-b-5 default-color">
                            <div className="radius border-default-color border m-x-5 p-5">{ac.oldData}</div>
                            <div className="radius border-default-color border m-x-5 p-5">{ac.newData}</div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default ActivityItem;
