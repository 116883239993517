import * as moment from "jalali-moment";
import { toLocalDate } from "./../../functions/index";

class ToPersianDate {
    dateTime;
    isString() {
        return typeof this.dateTime == "string";
    }
    changeType(dateTime) {
        this.dateTime = dateTime;
        if (this.isString()) {
            if (this.dateTime.split(".").length < 2) {
                return toLocalDate(dateTime);
            } else {
                return toLocalDate(this.dateTime.split(".")[0]);
            }
        } else {
            return dateTime;
        }
    }
    toDate(dateTime, changeType) {
        if (changeType) {
            dateTime = this.changeType(dateTime);
        }
        let date;

        try {
            date = moment(dateTime);
        } catch (err) {
            try {
                date = moment(toLocalDate(dateTime));
            } catch (err) {}
        }

        if (date == null) return;
        else return date;
    }

    getDateObject(dateTime) {
        return this.toDate(dateTime, true).toDate();
    }
    toFormat(dateTime, format) {
        let date = this.toDate(dateTime);

        if (date == null) return;

        if (format) {
            return date.format(format);
        } else {
            return;
        }
    }
}

export default ToPersianDate;
