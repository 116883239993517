import React, { Component } from "react";

export default class ReadMore extends Component {
    state = { isMoreActive: false };

    handleMore = () => {
        this.setState({ isMoreActive: !this.state.isMoreActive });
    };

    render() {
        if (this.state.isMoreActive) {
            return (
                <>
                    {this.props.children}
                    <span className="link-color bring-to-front pointer" onClick={this.handleMore}>
                        کمتر
                    </span>
                </>
            );
        }

        const children = String(this.props.children);

        if (children.length <= this.props.size) {
            return children;
        }

        let i;
        try {
            i = Number(this.props.size);
        } catch (error) {
            return children;
        }

        while (children[i] !== " ") {
            if (children.length === i) {
                return children;
            }
            i += 1;
        }
        return (
            <>
                {children.substring(0, i) + " ..."}{" "}
                <span className="link-color bring-to-front pointer" onClick={this.handleMore}>
                    بیشتر
                </span>
            </>
        );
    }
}
