import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../PageParts/Home/Dashboard";
import TopMenu from "../Layout/TopMenu";

class Home extends Component {
    async componentDidMount() {}
    state = { loading: true, isDashboard: true };
    render() {
        return (
            <div className="main-content">
                <div className="container">
                    <TopMenu isDashboard={true} />

                    <div className="back-smoke p-5 position-relative">
                        <Dashboard />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token, projectId: state.project.projectId };
};
export default connect(mapStateToProps)(Home);
