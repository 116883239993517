import React, { Component } from "react";
import { connect } from "react-redux";
import TopMenu from "../Layout/TopMenu";
import NewIdeaButton from "../PageParts/Ideas/NewIdeaButton";
import callServer from "./../../axios/callServer";

class Ideas extends Component {
    async componentDidMount() {
        const result = await callServer("get", "/Ideas/User", this.props.token);
        if (result == null) return;
        this.setState({ ideas: result, loading: false });
    }
    state = { ideas: [], loading: true };

    render() {
        return (
            <div className="main-content pos-rel">
                <div className="m-x-40">
                    <div className="container">
                        <TopMenu hasBackButton={true} />
                        <div className="back-smoke p-y-50 position-relative row ai-start">
                            <div className="lg-12">
                                <NewIdeaButton onChange={(idea) => this.setState({ ideas: [...this.state.ideas, idea] })} />
                            </div>
                            {this.state.ideas.length > 0 ? (
                                this.state.ideas.map((i) => (
                                    <div className="lg-3">
                                        <div className="d-flex flex-column ideas idea-box">
                                            <div className="d-flex ai-center jc-between">
                                                <div>
                                                    <span className="default-color f-12">
                                                        {i.user?.firstName} {i.user?.lastName}
                                                    </span>
                                                    <h2 className="p-y-5 m-b-0">{i.idea?.name}</h2>
                                                </div>
                                                <div>
                                                    <img className="mini-image" src={i.idea?.project?.image?.url} alt="" />
                                                </div>
                                            </div>
                                            <div>{i.idea?.description}</div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="lg-12 default-color text-center p-y-15">
                                    <span>ایده ای وجود ندارد</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token };
};
export default connect(mapStateToProps)(Ideas);
