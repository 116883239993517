import cookie from "react-cookies";

const INITIAL_STATE = {
    projectId: cookie.load("projectId"),
    projectName: "درحال باگزاری...",
    projectColor: null,
    projectImageUrl: null,
    project: { name: "در حال بارگزاری ..." },
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SELECT_PROJECT":
            cookie.save("projectId", action.payload.id, { path: "/" });
            return { ...state, projectId: action.payload.id, project: action.payload };
        case "DESELECT_PROJECT":
            cookie.remove("projectId");
            return { ...state, projectId: null, project: null };
        case "SIGN_OUT":
            cookie.remove("projectId");
            return { ...state, projectId: null, project: null };
        case "SET_PROJECT":
            return {
                ...state,
                project: action.payload,
            };
        default:
            return state;
    }
};
