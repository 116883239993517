import React from "react";
import Button from "./../../Elements/Button";
import SimpleReactValidator from "simple-react-validator";
import ColorPicker from "./../../Elements/ColorPicker";
import FileUploader from "../../Elements/FileUploader/FileUploader";
import { connect } from "react-redux";
import toastr from "toastr";
import axios from "axios";
import callServer from "../../../axios/callServer";
import Loading from "../../Elements/Loading";

class SocialMediaItem extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        this.setState({
            files: this.getStartFile(),
        });
    }

    getStartFile = (url, deleted) => {
        if (deleted || (this.state.imageUrl == null && url == null)) {
            return null;
        }
        return [
            {
                source: url == null ? this.state.imageUrl : url,
                options: {
                    type: "local",
                    metadata: {
                        poster: url == null ? this.state.imageUrl : url,
                    },
                },
            },
        ];
    };
    state = {
        loading: false,
        id: this.props.socialMedia.id,
        name: this.props.socialMedia.name,
        imageUrl: this.props.socialMedia.imageUrl,
        imageAlt: this.props.socialMedia.imageAlt,
        color: this.props.socialMedia.color,
        newImageUrl: null,
    };

    render() {
        const deleteSocialNetwork = async () => {
            if (window.confirm("آیا اطمینان دارید؟")) {
                this.setState({ loading: true });
                const data = await callServer("post", `/SocialNetworks/delete`, this.props.token, {
                    id: this.state.id,
                });
                this.setState({ loading: false });
                if (data == null) return;
                this.props.deleteSocialMedia(this.state.id);
                toastr.success("با موفقیت حذف شد!");
            } else {
                toastr.info("عملیات لغو شد!");
            }
        };
        const changeSocialNetwork = async () => {
            if (this.state.name.length > 0) {
                this.setState({ loading: true });
                const data = await callServer("post", `/SocialNetworks/Edit`, this.props.token, {
                    id: this.state.id,
                    name: this.state.name,
                    color: this.state.color,
                    newImageUrl: this.state.newImageUrl,
                });
                this.setState({
                    loading: false,
                    id: data.id,
                    name: data.name,
                    imageUrl: data.imageUrl,
                    imageAlt: data.imageAlt,
                    color: data.color,
                    newImageUrl: null,
                    files: this.getStartFile(data.imageUrl, data.imageUrl == null),
                });
                toastr.success("با موفقیت تغییر یافت");
            } else {
                toastr.warning("لطفا تمام موارد را پر کنید!");
            }
        };

        if (this.state.loading)
            return (
                <div key={this.state.id} className="lg-3 md-6">
                    <div className="box pos-rel" style={{ border: `1px solid ${this.state.color}` }}>
                        <div className="p-y-100">
                            <Loading />
                        </div>
                    </div>
                </div>
            );
        return (
            <div key={this.state.id} className="lg-3 md-6">
                <div className="box" style={{ border: `1px solid ${this.state.color}` }}>
                    <div className="text-center p-t-5 f-14 p-b-5">{this.state.name}</div>
                    <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                        <p>نام :</p>
                        <div className="grow-1 p-r-10">
                            <input
                                type="text"
                                onChange={(e) => this.setState({ name: e.target.value })}
                                value={this.state.name}
                                className="input "
                            />
                            {this.validator.message("name", this.state.name, "required|alpha")}
                        </div>
                    </div>
                    <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                        <p>عکس :</p>
                        <div className="grow-1 p-r-10">
                            <FileUploader
                                name="file"
                                server={{
                                    process: {
                                        url: `/ImageUploader/${this.props.projectId}`,
                                        headers: {
                                            Authorization: `Bearer ${this.props.token}`,
                                        },
                                        onload: (par) => {
                                            par = JSON.parse(par);
                                            if (par.imageUrl) {
                                                this.setState({
                                                    newImageUrl: par.imageUrl,
                                                });
                                            }
                                        },
                                    },
                                    revert: (par, load) => {
                                        console.log(par);
                                        load();
                                    },
                                    remove: (par, load) => {
                                        console.log(par);
                                        this.setState({
                                            newImageUrl: "",
                                        });
                                        load();
                                    },
                                }}
                                files={this.state.files}
                                onChange={({ files }) => {
                                    this.setState({ files });
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                        <p>رنگ :</p>
                        <div className=" p-r-10">
                            <ColorPicker onChange={(color) => this.setState({ color })} value={this.state.color} />
                        </div>
                    </div>
                    <div className="d-flex ai-center jc-between p-t-10">
                        <div className="min-h-1px">
                            <div className="pointer" onClick={deleteSocialNetwork}>
                                <Button>
                                    <span className="iconly-brokenDelete p-l-5"></span>
                                    حذف
                                </Button>
                            </div>
                        </div>
                        <div className="pointer" onClick={changeSocialNetwork}>
                            <Button>
                                <span className="iconly-brokenPlus p-l-5"></span>
                                اعمال تغییرات
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { token: state.auth.token };
};
export default connect(mapStateToProps)(SocialMediaItem);
