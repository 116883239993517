import React, { Component } from "react";

export default class UserImage extends Component {
    render() {
        const u = this.props.user;
        if (u.imageUrl) {
            return (
                <div key={u.id} className={`task-item task-item-text m-x-10 ${this.props.className}`}>
                    <img className="w-100" src={u.imageUrl} alt="" />
                </div>
            );
        } else if (u.image?.url) {
            return (
                <div key={u.id} className={`task-item task-item-text m-x-10 ${this.props.className}`}>
                    <img className="w-100" src={u.image?.url} alt="" />
                </div>
            );
        } else {
            return (
                <div key={u.id} className={`task-item task-item-text m-x-10 ${this.props.className}`}>
                    {u.firstName[0]} {u.lastName[0]}
                </div>
            );
        }
    }
}
