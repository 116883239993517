import React, { Component } from "react";

export default class EventsReadMore extends Component {
    render() {
        const children = String(this.props.children);

        if (children.length <= this.props.size) {
            return children;
        }

        let i;
        try {
            i = Number(this.props.size);
        } catch (error) {
            return children;
        }

        while (children[i] !== " ") {
            if (children.length === i) {
                return children;
            }
            i += 1;
        }
        return <>{children.substring(0, i) + " ..."}</>;
    }
}
