import React from "react";
import Button from "../../Elements/Button";
import PerfectScrollbar from "react-perfect-scrollbar";
import toastr from "toastr";
import Loading from "../../Elements/Loading";
import CommentItem from "./CommentItem";
import callServer from "../../../axios/callServer";
import {connect} from "react-redux";

class CommentBox extends React.Component {
    state = {newCommentContent: "", loading: false, comments: null};

    async componentDidMount() {
    }

    componentDidUpdate() {
    }

    handleNewComment = async (e) => {
        if (this.state.newCommentContent.replaceAll(" ", "").length < 1) {
            toastr.error("متن نظر را وارد کنید!");
            return;
        }
        this.setState({loading: true});
        const result = await callServer("post", `Comments/Create`, this.props.token, {
            content: this.state.newCommentContent,
            commentTypeId: this.props.commentTypeId,
            postId: this.props.postId,
        })
        if (result == null) {
            toastr.error("درحواست با خطا مواجه شد");
            return;
        }

        await this.props.reloadComments();
        this.setState({newCommentContent: "", loading: false, comments: null});
    }

    render() {
        const comments = this.props.comments?.filter(c => c.comment.commentTypeId === this.props.commentTypeId);
        if (comments == null || this.state.loading)
            return (
                <div className="comments">
                    {this.props.dontShowTitle ? null : (
                        <p className="p-b-15">نظرات</p>
                    )}
                    <div className="pos-rel min-h-200px">
                        <Loading/>
                    </div>
                </div>
            );
        return (
            <div className="comments">
                {this.props.dontShowTitle ? null : (
                    <p className="p-b-15">نظرات</p>
                )}
                <div className="d-flex jc-end ai-end p-b-15">
                    <textarea value={this.state.newCommentContent}
                              onChange={(e) => this.setState({newCommentContent: e.target.value})}
                              className="w-100 p-x-15 p-y-10 comment-textarea radius" placeholder="نظر خود را بنویسید"/>
                    <div className="m-r-15 m-l-5" onClick={this.handleNewComment} type="submit">
                        <Button>تایید</Button>
                    </div>
                </div>

                <div className="comments-parent">
                    <PerfectScrollbar style={{maxHeight: 250, paddingLeft: 10, paddingRight: 10}}>
                        {
                            comments.sort(function (a, b) {
                                return new Date(b.time).getTime() - new Date(a.time).getTime();
                            }).map((c) => (
                                    <CommentItem comment={c}/>
                                )
                            )
                        }
                    </PerfectScrollbar>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {token: state.auth.token};
};
export default connect(mapStateToProps)(CommentBox);
