import React, { Component } from "react";
import callServer from "./../../../../axios/callServer";
import { connect } from "react-redux";
import Loading from "../../../Elements/Loading";
import * as moment from "jalali-moment";
import PostItem from "./../../Common/PostItem";

class NextPost extends Component {
    state = { isReady: false, post: null };
    async componentDidMount() {
        const result = await callServer("get", "/Posts/NextPost", this.props.token);
        if (result == null) return;
        this.setState({
            isReady: true,
            post: result.post,
        });
    }
    render() {
        if (this.state.isReady && this.state.post == null)
            return (
                <div className="row h-100">
                    <div className="lg-12 m-t-10">
                        <div className="d-flex flex-column projects">
                            <h2>نزدیک‌ترین پست برای انتشار</h2>
                            <h2 className="p-t-50 f-18 text-center default-color">پستی وجود ندارد</h2>
                        </div>
                    </div>
                </div>
            );
        return (
            <div className="row h-100">
                <div className="lg-12 m-t-10">
                    <div className="d-flex flex-column projects next-post-container">
                        <h2>نزدیک‌ترین پست برای انتشار</h2>
                        {this.state.isReady ? (
                            <PostItem post={this.state.post} />
                        ) : (
                            <div className="pos-rel p-t-100">
                                <Loading />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token, projectId: state.project.projectId };
};
export default connect(mapStateToProps)(NextPost);
