import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { register } from "../../actions";
import toastr from "toastr";

class Register extends React.Component {
    state = { firstName: "", lastname: "", password: "", email: "" };
    render() {
        return (
            <div className="new-design">
                <div className="login-section-new">
                    <div className="container">
                        <div className="screen p-x-15">
                            <div className="row">
                                <div className="lg-5">
                                    <div className="screen__content">
                                        <from className="login" onSubmit={(e) => e.preventDefault()}>
                                            <div className="p-b-10 p-t-20">
                                                <img className="login-logo" src="/images/logo.png" />
                                            </div>
                                            <div className="p-y-10 text-center">لطفا مشخصات خود را وارد کنید</div>

                                            <div className="login__field p-y-10">
                                                <input
                                                    autoComplete="off"
                                                    value={this.state.firstName}
                                                    onChange={(e) => this.setState({ firstName: e.target.value })}
                                                    type="text"
                                                    className="w-100 input"
                                                    placeholder="نام"
                                                />
                                            </div>
                                            <div className="login__field p-y-10">
                                                <input
                                                    autoComplete="off"
                                                    value={this.state.lastname}
                                                    onChange={(e) => this.setState({ lastname: e.target.value })}
                                                    type="text"
                                                    className="w-100 input"
                                                    placeholder="نام خانوادگی"
                                                />
                                            </div>
                                            <div className="login__field p-y-10">
                                                <input
                                                    autoComplete="off"
                                                    value={this.state.email}
                                                    onChange={(e) => this.setState({ email: e.target.value })}
                                                    type="text"
                                                    className="w-100 input"
                                                    placeholder="ایمیل"
                                                />
                                            </div>
                                            <div className="login__field p-t-10 p-b-25 m-b-20 pos-rel">
                                                <input
                                                    autoComplete="off"
                                                    value={this.state.password}
                                                    onChange={(e) => this.setState({ password: e.target.value })}
                                                    type="password"
                                                    className="w-100 input"
                                                    placeholder="رمزعبور"
                                                />
                                                <span className="red d-block register-message f-12">
                                                    رمز عبور باید شامل حروف کوچک و بزرگ و حداقل یک علامت (/%*&!) باشد!
                                                </span>
                                            </div>

                                            <div className="d-flex ai-center jc-center">
                                                <button
                                                    className="button login__submit"
                                                    onClick={() => {
                                                        if (this.state.email == null || this.state.password == null) {
                                                            toastr.warning("لطفا همه ی مشخصات را وارد نمایید");
                                                            return;
                                                        }
                                                        this.props.register({
                                                            firstName: this.state.firstName,
                                                            lastName: this.state.lastname,
                                                            email: this.state.email,
                                                            password: this.state.password,
                                                        });
                                                    }}
                                                >
                                                    <span className="button__text">ثبت نام</span>
                                                </button>
                                            </div>
                                            <Link className="p-b-30 p-t-5 f-12 d-block p-r-10 black text-center" to="Login">
                                                قبلا ثبت نام کردید؟
                                                <span className="white d-inblock p-r-5 default-color f-14">وارد شوید</span>
                                            </Link>
                                        </from>
                                        <div className="social-login">
                                            {/* <Link className="p-t-15 f-14 d-block default-color" to="Register">
                                                <span className="white d-inblock f-16">ثبت نام </span>
                                            </Link>
                                            <span className="white d-inblock f-16">فراموشی رمزعبور </span> */}
                                            {/* <div className="social-icons">
                                    <a className="social-login__icon fab fa-instagram">
                                        <span></span>
                                    </a>
                                    <a href="#" className="social-login__icon fab fa-facebook"></a>
                                    <a href="#" className="social-login__icon fab fa-twitter"></a>
                                </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="lg-7 p-0 d-flex">
                                    <div className="login-bg-img d-flex ai-center">
                                        <img src="/images/login.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, { register })(Register);
