import React from "react";
import Select from "../../Elements/Select";
import Button from "../../Elements/Button";
import toastr from "toastr";
import SimpleReactValidator from "simple-react-validator";
import Loading from "../../Elements/Loading";
import {connect} from "react-redux";
import callServer from "../../../axios/callServer";

class AddNewUser extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
    }

    state = {
        loading: false,
        newUserEmail: "",
        newUserAccessType: 2
    }
    addUserToProject = async () => {
        if (this.state.newUserEmail === null || this.state.newUserEmail === "") {
            toastr.error("بخش ایمیل باید پر شود!", "هشدار!");
            return;
        }
        if (!this.validator.allValid()) {
            toastr.error("ایمیل وارد شده صحیح نیست", "هشدار");
            return;
        }
        this.setState({loading: true});
        const data = await callServer("post", `/Projects/Users/Add`, this.props.token, {
            projectId: this.props.projectId,
            email: this.state.newUserEmail,
            accessTypeId: this.state.newUserAccessType,
        });
        this.setState({loading: false});
        if (data == null) return;
        // this.props.setState({users: [...this.props.users, data]});
        this.setState({newUserEmail: "", newUserAccessType: 2});
        toastr.success("درخواست با موفقیت ارسال شد!");
    };

    render() {
        if (this.state.loading) return (
            <div className="lg-4 md-6">
                <div className="box full-border pos-rel" style={{border: "1px solid black"}}>
                    <div className="p-y-50">
                        <Loading/>
                    </div>
                </div>
            </div>
        );
        return (
            <div className="lg-4 md-6">
                <div className="box full-border" style={{border: "1px solid black"}}>
                    <div className="text-center p-t-5 f-14 p-b-5">اضافه کردن کاربر جدید</div>
                    <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                        <p>ایمیل :</p>
                        <div className="grow-1 p-r-10">
                            <input
                                type="email"
                                onChange={(e) => this.setState({newUserEmail: e.target.value})}
                                value={this.state.newUserEmail}
                                className="input text-left"
                            />
                            {this.validator.message(
                                "email",
                                this.state.newUserEmail,
                                "required|email"
                            )}
                        </div>
                    </div>
                    <div className="d-flex ai-center jc-between p-t-10">
                        <Select
                            selectedOptionId={this.state.newUserAccessType}
                            selectOptions={this.props.accessTypes}
                            selectChange={(id) => this.setState({newUserAccessType: id})}
                        >
                            دسترسی
                        </Select>
                        <div className="pointer" onClick={this.addUserToProject}>
                            <Button>
                                <span className="iconly-brokenPlus p-l-5"></span>
                                افزودن
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        currentUserEmail: state.auth.user.email,
        currentUserId: state.auth.user.id,
    };
};
export default connect(mapStateToProps)(AddNewUser);
