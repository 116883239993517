import React from "react";

class Loading extends React.Component {
    render() {
        return (
            <div className="loading">
                <div>در حال دریافت اطلاعات</div>
                <div className="loading-anim"></div>
            </div>
        );
    }
}

export default Loading;
