import React from "react";
import SimpleReactValidator from "simple-react-validator";
import ChangePassword from "./../PageParts/Profile/ChangePassword";
import Button from "./../Elements/Button";
import { connect } from "react-redux";
import FileUploader from "./../Elements/FileUploader/FileUploader";
import axios from "axios";
import toastr from "toastr";
import { updateUser } from "./../../actions/index";
import Loading from "../Elements/Loading";
import callServer from "./../../axios/callServer";
import TopMenu from "./../Layout/TopMenu";
import DropDown from "./../Elements/DropDown";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
    }
    getStartFile = () => {
        if (this.state.imageUrl != null) {
            return [
                {
                    source: this.state.imageUrl,
                    options: {
                        type: "local",
                        metadata: {
                            poster: this.state.imageUrl,
                        },
                    },
                },
            ];
        } else {
            return null;
        }
    };
    async componentDidMount() {
        const data = await callServer("get", `/Users/CurrentUser`, this.props.token);
        if (data == null) return;
        this.props.updateUser(data);
        this.setState({ email: data.email, firstName: data.firstName, lastName: data.lastName, imageUrl: data.imageUrl });
        this.setState({ files: this.getStartFile() });
    }
    state = { files: null, email: null, firstName: null, lastName: null, imageUrl: null, newImageUrl: null };
    updateUser = async () => {
        const body = {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            newImageUrl: this.state.newImageUrl,
        };
        const data = await callServer("post", `/Users/CurrentUser`, this.props.token, body);
        if (data == null) return;
        this.props.updateUser(data);
        this.setState({
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            imageUrl: data.imageUrl,
            newImageUrl: null,
        });
        toastr.success("با موفقیت انجام شد");
    };
    render() {
        if (this.state.email != null)
            return (
                <div className="main-content pos-rel">
                    <div className="m-x-40">
                        <div className="container">
                            <TopMenu hasBackButton={true} />
                            <div className="back-smoke p-y-50 position-relative row ai-center jc-center">
                                <div className="lg-8">
                                    <DropDown name="ویرایش مشخصات" isOpen className="p-0 m-b-15 post-text" titleClassName="text-bold">
                                        <div className="dir-rtl p-b-15 p-x-30">
                                            <div className="row">
                                                <div className="lg-6">
                                                    <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                                                        <p>نام :</p>
                                                        <div className="grow-1 p-r-10">
                                                            <input
                                                                type="text"
                                                                onChange={(e) => this.setState({ firstName: e.target.value })}
                                                                value={this.state.firstName}
                                                                className="input"
                                                            />
                                                            {this.validator.message(
                                                                "firstName",
                                                                this.state.firstName,
                                                                "required|alpha"
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                                                        <p>نام‌خانوادگی :</p>
                                                        <div className="grow-1 p-r-10">
                                                            <input
                                                                type="text"
                                                                onChange={(e) => this.setState({ lastName: e.target.value })}
                                                                value={this.state.lastName}
                                                                className="input"
                                                            />
                                                            {this.validator.message("lastName", this.state.lastName, "required|alpha")}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                                                        <p>ایمیل :</p>
                                                        <div className="grow-1 p-r-10">
                                                            <input
                                                                type="email"
                                                                onChange={(e) => this.setState({ email: e.target.value })}
                                                                value={this.state.email}
                                                                className="input text-left"
                                                                disabled="disabled"
                                                            />
                                                            {this.validator.message("email", this.state.email, "required|email")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="lg-6">
                                                    <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                                                        <p>عکس :</p>
                                                        <div className="grow-1 black-filepond p-r-10">
                                                            <FileUploader
                                                                name="file"
                                                                server={{
                                                                    process: {
                                                                        url: `/ImageUploader`,
                                                                        headers: {
                                                                            Authorization: `Bearer ${this.props.token}`,
                                                                        },
                                                                        onload: (par) => {
                                                                            par = JSON.parse(par);
                                                                            if (par.imageUrl) {
                                                                                this.setState({
                                                                                    newImageUrl: par.imageUrl,
                                                                                });
                                                                            }
                                                                        },
                                                                    },
                                                                    revert: (par, load) => {
                                                                        console.log(par);
                                                                        load();
                                                                    },
                                                                    remove: (par, load) => {
                                                                        console.log(par);
                                                                        this.setState({
                                                                            newImageUrl: "",
                                                                        });
                                                                        load();
                                                                    },
                                                                }}
                                                                files={this.state.files}
                                                                onChange={({ files }) => {
                                                                    this.setState({ files });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="lg-12 text-center p-t-15">
                                                    <span onClick={this.updateUser}>
                                                        <Button>اعمال تغییرات </Button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </DropDown>
                                    <ChangePassword />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        else return <Loading />;
    }
}
const mapStateToProps = (state) => {
    return { user: state.auth.user, token: state.auth.token };
};
export default connect(mapStateToProps, { updateUser })(Profile);
