import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router";
import DesktopLayout from "./components/Layout/Desktop/DesktopLayout";
import Home from "./components/Pages/Home";
import Login from "./components/Pages/Login";
import Register from "./components/Pages/Register";
import ProjectUsers from "./components/Pages/ProjectUsers";
import Profile from "./components/Pages/Profile";
import ProjectSocialMedia from "./components/Pages/ProjectSocialMedia";
import ProjectSetting from "./components/Pages/ProjectSetting";
import Calendar from "./components/Pages/Calendar";
import Ideas from "./components/Pages/Ideas";
import Project from "./components/Pages/Project";
import UserInvitations from "./components/Pages/UserInvitations";
import ForgetPassword from "./components/Pages/ForgetPassword";
import ResetPassword from "./components/Pages/ResetPassword";

import { connect } from "react-redux";

import "./components/Style/Master.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import NewPost from "./components/Pages/NewPost";
import EditPost from "./components/Pages/EditPost";

class App extends Component {
    static displayName = App.name;

    render() {
        // let Layout =
        //     (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 600
        //         ? DesktopLayout
        //         : MobileLayout;
        let Layout = DesktopLayout;
        return (
            <Switch>
                <Route exact path="/ResetPassword" component={ResetPassword} />
                {this.props.isLoggedIn ? (
                    <Layout>
                        <Switch>
                            <Route exact path="/panel/project/SocialMedia/:id" component={ProjectSocialMedia} />
                            <Route exact path="/panel/Project/setting/:id" component={ProjectSetting} />
                            <Route exact path="/panel/project/Users/:id" component={ProjectUsers} />
                            <Route exact path="/panel/post/new" component={NewPost} />
                            <Route exact path="/panel/Post/:id" component={EditPost} />
                            <Route exact path="/panel/Calendar" component={Calendar} />
                            <Route exact path="/panel/Project/:id" component={Project} />
                            <Route exact path="/panel" component={Home} />
                            <Route exact path="/panel/Ideas" component={Ideas} />
                            <Route exact path="/Profile" component={Profile} />
                            <Route exact path="/Requests" component={UserInvitations} />
                            <Route
                                exact
                                path="/Login/:redirectUrl"
                                render={(props) => <Redirect to={`/${props.match.params.redirectUrl}`} />}
                            />
                            <Redirect from="/" to="/panel" />
                        </Switch>
                    </Layout>
                ) : (
                    <Switch>
                        <Route exact path="/Register" component={Register} />
                        <Route exact path="/Login" component={Login} />
                        <Route exact path="/ForgetPassword" component={ForgetPassword} />
                        <Route exact path="/Login/:redirectUrl" component={Login} />
                        <Redirect from="/" to="/Login" />
                    </Switch>
                )}
            </Switch>
        );
    }
}

const mapStateToProps = (state) => {
    return { isLoggedIn: state.auth.token, isProjectSelected: state.project.projectId };
};
export default connect(mapStateToProps)(App);
