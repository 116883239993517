import * as moment from "jalali-moment";
import React, { Component } from "react";
import JalaliDay from "./Parts/JalaliDay";
import "./style.scss";

export default class JalaliCalendar extends Component {
    state = {
        api: "https://persiancalapi.ir/jalali/{Year}/{Month}/{Day}",
        weekDaysName: ["شنبه", "یک‌شنبه", "دوشنبه", "سه‌شنبه", "چهارشنبه", "پنج‌شنبه", "جمعه"],
        now: new Date(),
    };

    componentDidMount() {
        this.setState({
            api: this.props.api ?? this.state.api,
            weekDaysName: this.props.weekDaysName ?? this.state.weekDaysName,
            now: this.props.now ?? this.state.now,
        });
        this.dateChangeHandle(this.props.now ?? new Date());
    }

    dateChangeHandle = (date) => {
        if (this.props.onDateChange)
            this.props.onDateChange(
                moment(date)
                    .locale("fa")
                    .startOf("jMonth")
                    .subtract(moment(date).locale("fa").startOf("jMonth").jDay() % 7, "jD"),
                moment(date)
                    .locale("fa")
                    .endOf("jMonth")
                    .add(7 - moment(date).locale("fa").endOf("jMonth").jDay(), "jD")
            );
    };

    todayButton = () => {
        this.setState({
            now: this.props.now ?? new Date(),
        });
        this.dateChangeHandle(this.props.now ?? new Date());
    };

    pervMonthButton = () => {
        this.setState({
            now: moment(this.state.now).locale("fa").subtract(1, "jMonth").toDate(),
        });
        this.dateChangeHandle(moment(this.state.now).locale("fa").subtract(1, "jMonth").toDate());
    };

    nextMonthButton = () => {
        this.setState({
            now: moment(this.state.now).locale("fa").add(1, "jMonth").toDate(),
        });
        this.dateChangeHandle(moment(this.state.now).locale("fa").add(1, "jMonth").toDate());
    };

    handleWeekNames = () => {
        return this.state.weekDaysName.map((name) => (
            <div key={name} className="jc-content-header-item">
                {name}
            </div>
        ));
    };

    handleDays = () => {
        return this.buildCalendar().map((day) => {
            return (
                <JalaliDay
                    today={this.props.now ?? new Date()}
                    key={day.date.format("jYYYY/jM/jD")}
                    day={day}
                    api={this.state.api}
                    contents={this.props.contents ?? []}
                    onClick={this.props.onDayClick}
                    dayHtml={this.props.dayHtml}
                />
            );
        });
    };

    buildCalendar = () => {
        const date = this.state.now;

        let datesArray = [];

        var startOfMonth = moment(date).locale("fa").startOf("jMonth");

        for (let i = startOfMonth.jDay() % 7; i > 0; i--) {
            datesArray.push({
                isActive: false,
                date: moment(date).locale("fa").startOf("jMonth").subtract(i, "jD"),
            });
        }

        for (let i = 0; i < moment(date).locale("fa").jDaysInMonth(); i++) {
            datesArray.push({
                isActive: true,
                date: moment(date).locale("fa").startOf("jMonth").add(i, "jD"),
            });
        }

        var endOfMonth = moment(date).locale("fa").endOf("jMonth");

        for (let i = 1; i < 7 - endOfMonth.jDay(); i++) {
            datesArray.push({
                isActive: false,
                date: moment(date).locale("fa").endOf("jMonth").add(i, "jD"),
            });
        }

        return datesArray;
        //console.log(moment().jDay(7).format("dddd"));
        //console.log(moment(date).jDay());

        //console.log(moment(date).startOf("jMonth").format("dddd"));
    };

    render() {
        return (
            <div className="jalali-calendar">
                <div className="jc-header">
                    <div className="jc-header-month">
                        {moment(this.state.now ?? new Date())
                            .locale("fa")
                            .format("jMMMM")}
                    </div>
                    <div>
                        <button onClick={this.pervMonthButton} className="jc-header-prev">
                            قبلی
                        </button>
                        <button onClick={this.nextMonthButton} className="jc-header-next">
                            بعدی
                        </button>
                        <button onClick={this.todayButton} className="jc-header-today">
                            امروز
                        </button>
                    </div>
                </div>
                <div className="jc-content">
                    <div className="jc-content-header">{this.handleWeekNames()}</div>
                    <div className="jc-content-body">{this.handleDays()}</div>
                </div>
            </div>
        );
    }
}
