import React, { Component } from "react";
import { connect } from "react-redux";
import { changePostPeople } from "./sendPostChanges";
import { setNotification } from "../../../actions";

class TaskPopup extends Component {
    state = {};
    showOtherUsers = () => {
        return this.props.users
            .filter((x) => {
                let isEq = true;
                this.props.currentUsers.forEach((it) => {
                    if (x.email == it.email) isEq = false;
                });
                return isEq;
            })
            .map((u) => {
                return (
                    <div key={u.id} className="d-flex ai-center jc-between p-x-5 p-y-10">
                        <div className="f-12">
                            <div>
                                {u.firstName} {u.lastName}
                            </div>
                            <div>{u.email}</div>
                        </div>
                        <span
                            className="f-16 red iconly-brokenPlus"
                            onClick={async () => {
                                //await changePostPeople(this.props.postId, this.props.token, "AddParticipate", u, this.props.type);
                                this.props.currentUsersChange([...this.props.currentUsers, u]);
                                //this.props.setNotification(this.props.token, this.props.projectId);
                            }}
                        ></span>
                    </div>
                );
            });
    };

    showCurrentUsers = () => {
        return this.props.currentUsers.map((u) => {
            return (
                <div key={u.id} className="d-flex ai-center jc-between p-x-5 p-y-10">
                    <div className="f-12">
                        <div>
                            {u.firstName} {u.lastName}
                        </div>
                        <div>{u.email}</div>
                    </div>
                    <span
                        className="f-16 green iconly-brokenDelete"
                        onClick={async () => {
                            //await changePostPeople(this.props.postId, this.props.token, "RemoveParticipate", u, this.props.type);
                            this.props.currentUsersChange(this.props.currentUsers.filter((cu) => cu.email != u.email));
                            // call server
                            //this.props.setNotification(this.props.token, this.props.projectId);
                        }}
                    ></span>
                </div>
            );
        });
    };

    render() {
        return (
            <div>
                <div className="auto-close bg-light-black z-index-1" onClick={this.props.hide}></div>
                <div className="task-popup">
                    <div className="f-16 text-center p-b-10">{this.props.title}</div>
                    <div className="current-users">
                        {this.showCurrentUsers().length > 0 ? (
                            <div className="f-12 default-color p-b-5 border-b border-default-color">اضافه شده</div>
                        ) : null}
                        {this.showCurrentUsers()}

                        {this.showOtherUsers().length > 0 ? (
                            <div className="f-12 default-color p-b-5 border-b border-default-color"> اعضای پروژه</div>
                        ) : null}
                        {this.showOtherUsers()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { token: state.auth.token, projectId: state.project.projectId };
};
export default connect(mapStateToProps, { setNotification })(TaskPopup);
