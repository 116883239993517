import React from "react";
import Button from "./../../Elements/Button";
import SimpleReactValidator from "simple-react-validator";
import ColorPicker from "./../../Elements/ColorPicker";
import toastr from "toastr";
import FileUploader from "./../../Elements/FileUploader/FileUploader";
import {connect} from "react-redux";
import axios from "axios";
import Loading from "../../Elements/Loading";
import callServer from "../../../axios/callServer";

class NewSocialMedia extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
    }

    resetFiles = () => {
    };
    state = {
        loading: false,
        id: this.props.socialMedia.id,
        name: this.props.socialMedia.name,
        imageUrl: this.props.socialMedia.imageUrl,
        imageAlt: this.props.socialMedia.imageAlt,
        color: "black",
        files: null,
    };

    render() {
        const newSocialNetwork = async () => {
            if (this.state.name.length > 0) {
                this.setState({loading: true});
                const data = await callServer("post", `/SocialNetworks/New/${this.props.projectId}`, this.props.token, {
                    name: this.state.name,
                    color: this.state.color,
                    newImageUrl: this.state.newImageUrl,
                })
                if (data == null) {
                    this.setState({
                        loading: false,
                        name: "",
                        imageUrl: "",
                        imageAlt: "",
                        color: null,
                        newImageUrl: "",
                        files: null,
                    });
                    return;
                }
                this.props.onAdd(data);
                this.setState({
                    loading: false,
                    name: "",
                    imageUrl: "",
                    imageAlt: "",
                    color: null,
                    newImageUrl: "",
                    files: null,
                });
                this.resetFiles();
                toastr.success("با موفقیت تغییر یافت");
            } else {
                toastr.warning("لطفا تمام موارد را پر کنید!");
            }
        };

        if (this.state.loading) return (
            <div key={this.state.id} className="lg-3 md-6">
                <div className="box pos-rel" style={{border: `1px solid ${this.state.color}`}}>
                    <div className="p-y-100">
                        <Loading/>
                    </div>
                </div>
            </div>
        );
        return (
            <div key={this.state.id} className="lg-3 md-6">
                <div className="box" style={{border: `1px solid ${this.state.color}`}}>
                    <div className="text-center p-t-5 f-14 p-b-5">{this.state.name}</div>
                    <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                        <p>نام :</p>
                        <div className="grow-1 p-r-10">
                            <input
                                type="email"
                                onChange={(e) => this.setState({name: e.target.value})}
                                value={this.state.name}
                                className="input "
                            />
                            {this.validator.message("email", this.state.name, "required|alpha")}
                        </div>
                    </div>
                    <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                        <p>عکس :</p>
                        <div className="grow-1 p-r-10">
                            <FileUploader
                                name="file"
                                server={{
                                    process: {
                                        url: `/ImageUploader/${this.props.projectId}`,
                                        headers: {
                                            Authorization: `Bearer ${this.props.token}`,
                                        },
                                        onload: (par) => {
                                            par = JSON.parse(par);
                                            if (par.imageUrl) {
                                                this.setState({
                                                    newImageUrl: par.imageUrl,
                                                });
                                            }
                                        },
                                    },
                                    revert: (par, load) => {
                                        console.log(par);
                                        load();
                                    },
                                    remove: (par, load) => {
                                        console.log(par);
                                        load();
                                    },
                                }}
                                files={this.state.files}
                                onChange={({files}) => {
                                    this.setState({files});
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                        <p>رنگ :</p>
                        <div className=" p-r-10">
                            <ColorPicker onChange={(color) => this.setState({color})} value={this.state.color}/>

                            {this.validator.message("color", this.state.color, "required|alpha")}
                        </div>
                    </div>
                    <div className="d-flex ai-center jc-between p-t-10">
                        <div className="min-h-1px"></div>
                        <div className="pointer" onClick={newSocialNetwork}>
                            <Button>
                                <span className="iconly-brokenPlus p-l-5"></span>
                                اضافه کردن
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {token: state.auth.token};
};
export default connect(mapStateToProps)(NewSocialMedia);
