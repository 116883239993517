import React, { Component } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
const customStyles = {
    menu: (defaults) => {
        return { ...defaults, backgroundColor: "#efefef" };
    },
    control: (defaults) => {
        return { ...defaults, backgroundColor: "#efefef", border: "0px", boxShadow: "none", borderBottom: "2px solid #bcbcbc" };
    },

    placeholder: (defaults) => {
        return { ...defaults, color: "#000" };
    },
};

class ReactSelect extends Component {
    state = {};

    handleNew = (list, item) => {
        if (item.label[0] !== "#") {
            item.label = "#" + item.label;
            item.value = item.label;
        }
        if (item.label.split("#").length > 2 && item.value == list[list.length - 1].value) {
            var arr = item.label.split("#");
            arr.shift();
            item.label = "#" + arr[0].trim().split(" ").join("_");
            item.value = "#" + arr[0].trim().split(" ").join("_");
            arr.shift();
            arr.forEach((el) => {
                if (el.length > 0) {
                    el = el.trim();
                    const newItem = { ...item };
                    newItem.value = "#" + el.split(" ").join("_");
                    newItem.label = "#" + el.split(" ").join("_");
                    newItem.__isNew__ = true;
                    list.push(newItem);
                }
            });
        } else {
            item.label = item.label.trim().split(" ").join("_");
        }

        return list;
    };

    handleChange = (list) => {
        if (this.props.tagMannager) {
            list.forEach((el) => {
                if (el.__isNew__) {
                    list = this.handleNew(list, el);
                }
            });
        }
        this.props.onChange(list);
        console.log(list);
    };

    render() {
        if (this.props.isCreatable)
            return (
                <div className={this.props.className}>
                    <CreatableSelect
                        onChange={this.handleChange}
                        onCreate={(e) => console.log(e)}
                        styles={customStyles}
                        closeMenuOnSelect={false}
                        defaultValue={this.props.defaultValue ?? []}
                        isMulti={this.props.isMulti ?? false}
                        options={this.props.options ?? []}
                        placeholder={<div>{this.props.text ?? "بنویسید و اینتر بزنید ..."}</div>}
                        noOptionsMessage={(e) => this.props.noOptionsMessage ?? "موردی وجود ندارد"}
                        formatCreateLabel={(e) => `اضافه کردن «${e}»`}
                    />
                </div>
            );
        else
            return (
                <div className={this.props.className}>
                    <Select
                        isDisabled={this.props.isDisabled ?? false}
                        onChange={this.props.onChange}
                        styles={customStyles}
                        closeMenuOnSelect={!(this.props.isMulti ?? false)}
                        components={animatedComponents}
                        defaultValue={this.props.defaultValue ?? []}
                        isMulti={this.props.isMulti ?? false}
                        options={this.props.options ?? []}
                        placeholder={<div>{this.props.text ?? "انتخاب کنید ..."}</div>}
                        noOptionsMessage={(e) => this.props.noOptionsMessage ?? "موردی وجود ندارد"}
                    />
                </div>
            );
    }
}

export default ReactSelect;
