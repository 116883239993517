import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import * as moment from "jalali-moment";
import callServer from "./../../../axios/callServer";
import { Carousel } from "react-responsive-carousel";
import JalaliCalendar from "../../Elements/Calendar/JalaliCalendar";
import PostItem from "../Common/PostItem";
import Slider from "../../Elements/Slider";
import ReadMore from "../../Elements/ReadMore";
import ToPersianDate from "./../../Elements/ToPersianDate";

class NewCalendar extends Component {
    state = { posts: [], now: null, dayDate: null, dayContents: [], dayEvents: [] };

    async componentDidMount() {
        const res = await callServer("get", "/posts/today", this.props.token);
        if (res == null || res == "") return;
        this.setState({ now: new Date(res) });
    }

    onDateChange = async (startDate, endDate) => {
        const posts = await callServer("post", "/posts/userPosts", this.props.token, {
            startTime: startDate,
            endTime: endDate,
        });
        this.setState({ posts });
    };

    onDayClick = (date, contents, events) => {
        this.setState({
            dayDate: date,
            dayContents: contents,
            dayEvents: events,
        });
    };

    renderTags(post) {
        return post.tags.map((tag) => {
            return <span>#{tag.title} ‌ </span>;
        });
    }

    renderPostMedia(post) {
        if (post.media == null) return;
        else if (post.media.length > 0) {
            return post.media.map((media) => {
                const arr = media.url?.split(".");
                if (arr[arr.length - 1] == "mp4") {
                    return (
                        <div key={media.id}>
                            <video className="w-100" height="240" controls>
                                <source src={media.url} type="video/mp4" />
                            </video>
                        </div>
                    );
                } else {
                    return (
                        <div key={media.id}>
                            <img src={media.url} alt="" />
                        </div>
                    );
                }
            });
        }
    }

    render() {
        const showCalendar = () => {
            if (this.state.now == null) {
                return <div>Loading</div>;
            } else {
                return (
                    <JalaliCalendar
                        now={this.state.now}
                        onDateChange={this.onDateChange}
                        contents={this.state.posts.map((e) => {
                            let time = e.time + ".000Z";
                            let date = new Date(time);
                            return {
                                ...e,
                                date,
                                image: e.socialNetworkImage,
                            };
                        })}
                        onDayClick={this.onDayClick}
                    />
                );
            }
        };

        const showDayPosts = () => {
            if (this.state.dayDate == null) {
                return <div className="text-center">لطفا یک روز را انتخاب کنید</div>;
            }

            const posts = this.state.dayContents;

            return posts.map((p) => {
                return (
                    <Link to={`/panel/post/${p.id}`}>
                        <div className="preview-post-calendar">
                            <div>{p.title}</div>
                            <Slider>{this.renderPostMedia(p)}</Slider>
                            <div className="text-justify">
                                <ReadMore size={300}>{p.content}</ReadMore>
                            </div>
                            <div>{this.renderTags(p)}</div>
                            <div className="text-left">{new ToPersianDate().toDate(p.date, true).locale("fa").calendar()}</div>
                        </div>
                    </Link>
                );
            });
        };

        const showDayEvents = () => {
            if (this.state.dayDate == null) {
                return;
            }

            const events = this.state.dayEvents.events;

            return events?.map((e) => {
                return <div>{e.description}</div>;
            });
        };
        return (
            <div className="p-t-20">
                <div className="row ">
                    <div className="lg-3">
                        <div className="row h-100">
                            <div className="lg-12 m-t-10">
                                <div className="d-flex flex-column projects">
                                    <h2 className="m-0-im p-b-10">رویداد ها</h2>
                                    <div className="p-b-30">{showDayEvents()}</div>
                                    <h2>پست ها</h2>
                                    <div>{showDayPosts()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg-9">
                        <div className="dir-rtl p-t-10 p-b-15">{showCalendar()}</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { projectId: state.project.projectId, token: state.auth.token };
};
export default connect(mapStateToProps)(NewCalendar);
