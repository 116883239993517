import React, { Component } from "react";
import callServer from "./../../../../axios/callServer";
import { connect } from "react-redux";
import Loading from "../../../Elements/Loading";
import * as moment from "jalali-moment";

class RecentIdeas extends Component {
    state = { isReady: false, ideas: [] };
    async componentDidMount() {
        const result = await callServer("get", "/Ideas/Summary", this.props.token);
        if (result == null) return;
        this.setState({
            isReady: true,
            ideas: result,
        });
    }
    render() {
        return (
            <div className="home-ideas">
                <h2>ایده‌ها</h2>
                <div className="ideas-repeater">
                    {this.state.isReady ? (
                        this.state.ideas?.map((i) => {
                            return (
                                <div className="each-idea">
                                    <span>
                                        {i.description?.length < 140 ? i.description : i.description.substring(0, 140) + "..."}
                                    </span>
                                </div>
                            );
                        })
                    ) : (
                        <div className="pos-rel p-t-100">
                            <Loading />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token, projectId: state.project.projectId };
};
export default connect(mapStateToProps)(RecentIdeas);
