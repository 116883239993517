import React from "react";
import CheckBox from "../../Elements/CheckBox";
import Select from "../../Elements/Select";
import callServer from "../../../axios/callServer";

class Notificaions extends React.Component {
    state = {
        isEmailChecked: false,
        timeSelectId: null,
        isSMSChecked: false,
        isSendBeforeEnabled: false,
    };

    handleClick = async () => {
        await callServer("post", "Notificaions");
    };

    render() {
        return (
            <div className="notif-setting">
                <div className="heading">
                    <span>تنظیم نوتیف</span>
                </div>
                <div className="notif">
                    <div className="notif-service">
                        <h3>ارسال از طریق</h3>

                        <CheckBox
                            className="m-t-5"
                            label="ایمیل"
                            value={this.state.isEmailChecked}
                            onChange={(val) => this.setState({ isEmailChecked: val })}
                        />
                        <CheckBox
                            className="m-t-5"
                            label="پیامک"
                            value={this.state.isSMSChecked}
                            onChange={(val) => this.setState({ isSMSChecked: val })}
                        />
                    </div>
                    <div className="notif-date ai-center m-t-15">
                        <div className="m-b-15 p-l-10">
                            <CheckBox
                                value={this.state.isSendBeforeEnabled}
                                onChange={(val) => this.setState({ isSendBeforeEnabled: val })}
                            ></CheckBox>
                        </div>
                        <span className="d-row f-14">
                            همچنین به من
                            <Select
                                className="d-inblock m-x-5"
                                value={this.state.timeSelectId}
                                selectOptions={[
                                    { id: 1, name: "۳۰ دقیقه" },
                                    { id: 0, name: "دیگر" },
                                ]}
                                onChange={(val) => this.setState({ timeSelectId: val })}
                            />
                            قبل از تاریخ ارسال برای من پیام بفرست
                        </span>
                    </div>
                    <div className="notif-btn m-t-10 text-left">
                        <a onClick={this.handleClick}>تایید</a>
                    </div>
                    {/*<div className="notif-status m-t-10">
                        <span>چهارشنبه</span>
                        <p>
                            ساعت
                            <span>12</span>
                        </p>
                        <p>
                            App
                            <span>Notification</span>
                        </p>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default Notificaions;
