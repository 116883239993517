import React from "react";
import CheckBox from "../../Elements/CheckBox";

class ConfirmBox extends React.Component {
    render() {
        return (
            <div className="confirmation d-flex ai-center">
                <div className="grow-1">
                    <span>{this.props.title}</span>
                </div>
                <div className="people-to-confirm">
                    <CheckBox className="p-x-10 p-y-5 f-14" label={this.props.checkBoxLabel} value={this.props.value}
                              onChange={this.props.onChange}/>
                </div>
            </div>
        );
    }
}

export default ConfirmBox;
