/* eslint-disable import/no-anonymous-default-export */

const INITIAL_STATE = {
    notifications: [],
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_NOTIFICATION":
            return {
                ...state,
                notifications: action.payload,
            };
        default:
            return state;
    }
};
