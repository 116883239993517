import React, { Component } from "react";
import DesktopHeader from "./DesktopHeader";
class DesktopLayout extends Component {
    state = {};
    render() {
        return (
            <div className="new-design">
                <DesktopHeader />
                <div className="box-section">
                    <div className="lg-12 min-height-page-mobile">{this.props.children}</div>
                </div>
            </div>
        );
    }
}

export default DesktopLayout;
