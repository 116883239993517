import React, {Component} from "react";
import {connect} from "react-redux";
import FileUploader from "../../Elements/FileUploader/FileUploader";
import Select from "./../../Elements/Select";
import callServer from "./../../../axios/callServer";
import Loading from "./../../Elements/Loading";
import ReactSelect from "./../../Elements/ReactSelect";
import toastr from "toastr";

class Ideas extends Component {
    async componentDidMount() {
        const result = await callServer("get", "/projects", this.props.token);
        if (result == null) return;
        this.setState({
            projects: result.map((p) => {
                return {value: p.id, label: p.name};
            }),
        });
    }

    handleNewButton = async () => {
        if (this.state.name.length < 1 || this.state.projectId == null) return toastr.error("پروژه و نام الزامی است!");
        let mediaCollections = this.state.urls.map((u) => {
            return {
                url: u,
            };
        });
        let newIdea = {
            name: this.state.name,
            description: this.state.description,
            mediaCollections,
            projectId: this.state.projectId,
        };
        const result = await callServer("post", "/Ideas/New", this.props.token, newIdea);
        if (result == null) return;
        this.props.onChange(result);
        this.setState({
            showFullNew: false,
            files: null,
            name: "",
            description: "",
            projectId: null,
            projects: null,
            urls: []
        });
    };
    state = {showFullNew: false, files: null, name: "", description: "", projectId: null, projects: null, urls: []};

    render() {
        if (this.state.projects == null) return <Loading/>;
        return (
            <div className="p-b-15">
                <div className="d-flex">
                    <a className="new-button" onClick={() => this.setState({showFullNew: !this.state.showFullNew})}>
                        <i className="zmdi zmdi-file-plus m-l-5"></i>
                        ایده جدید
                    </a>
                </div>
                {this.state.showFullNew ? (
                    <div className="new-idea-menu">
                        <div className="row">
                            <div className="grow-1">
                                <div className="d-flex p-b-15 ai-center jc-between">
                                    <div className="d-flex ai-center jc-center p-l-10">
                                        <span className="p-l-5">عنوان پست:</span>
                                        <div>
                                            <input
                                                type="text"
                                                onChange={(e) => this.setState({name: e.target.value})}
                                                value={this.state.name}
                                                className="input "
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex ai-center jc-center p-l-10">
                                        <span className="p-l-5">پروژه:</span>
                                        <div style={{minWidth: "20vw"}}>
                                            <ReactSelect
                                                onChange={async (item) => {
                                                    this.setState({
                                                        projectId: item.value,
                                                    });
                                                }}
                                                options={this.state.projects}
                                                className="m-t-5"
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex ai-center p-r-15 grow-1 jc-center">
                                        <span className="p-l-5">مدیا:</span>
                                        <div className="grow-1">
                                            <FileUploader
                                                allowMultiple
                                                name="file"
                                                server={{
                                                    process: {
                                                        url: `/ImageUploader`,
                                                        headers: {
                                                            Authorization: `Bearer ${this.props.token}`,
                                                        },
                                                        onload: (par) => {
                                                            par = JSON.parse(par);
                                                            if (par.imageUrl) {
                                                                this.setState({
                                                                    urls: [...this.state.urls, par.imageUrl],
                                                                });
                                                            }
                                                            return par.imageUrl;
                                                        },
                                                    },
                                                    revert: async (par, load, a, b, c) => {
                                                        try {
                                                            // remove
                                                            this.setState({
                                                                urls: this.state.urls.filter((u) => u != par),
                                                            });
                                                            load();
                                                        } catch {
                                                            toastr.error("خطا در برقراری ارتباط با سرور");
                                                        }
                                                    },
                                                    remove: async (par, load) => {
                                                        try {
                                                            //remove
                                                            console.log(par);
                                                            load();
                                                        } catch {
                                                            toastr.error("خطا در برقراری ارتباط با سرور");
                                                        }
                                                    },
                                                    load: async (par, load) => {
                                                        const response = await fetch(par);
                                                        const fileName = [...response.url.split("/")].pop();
                                                        const blob = await response.blob();
                                                        const file = new File([blob], fileName, {type: blob.type});
                                                        console.log(file);

                                                        load(file);
                                                    },
                                                }}
                                                files={this.state.files}
                                                onChange={({files}) => {
                                                    this.setState({files});
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <span>توضیحات:</span>
                                    <div className="grow-1 ">
                                        <textarea
                                            type="text"
                                            onChange={(e) => this.setState({description: e.target.value})}
                                            value={this.state.description}
                                            className="input w-100"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex self-end p-r-15 m-b-10">
                                <a onClick={this.handleNewButton} className="button-green">
                                    انتشار
                                </a>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {token: state.auth.token};
};
export default connect(mapStateToProps)(Ideas);
