import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import toastr from "toastr";
import callServer from "./../../axios/callServer";
import queryString from "query-string";

class ResetPassword extends React.Component {
    state = { password: "", repeatPassword: "", wasEditSuccessful: false, token: null };
    resetPassword = async () => {
        const result = await callServer("post", "/Users/resetPassword", this.state.token, {
            newPassword: this.state.password,
        });
        if (result) this.setState({ wasEditSuccessful: true });
    };

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        this.setState({ token: values.user });
    }
    render() {
        if (this.state.wasEditSuccessful)
            return (
                <div className="ai-center d-flex f-18 flex-column green jc-center p-y-100 w-100">
                    با موفقیت انجام شد
                    <div>
                        <Link className="default-color button d-block f-14 m-t-15 p-t-15 p-y-0" to="/Login">
                            <span className="white d-inblock f-16"> وارد شوید </span>
                        </Link>
                    </div>
                </div>
            );
        else if (this.state.token == null)
            return <div className="w-100 d-flex ai-center jc-center f-18 red p-y-100">لینک اشتباه است!</div>;
        return (
            <div className="new-design">
                <div className="login-section-new">
                    <div className="container">
                        <div className="screen p-x-15">
                            <div className="row">
                                <div className="lg-5">
                                    <div className="screen__content">
                                        <div className="login">
                                            <div className="p-b-10 p-t-20">
                                                <img className="login-logo" src="/images/logo.png" />
                                            </div>
                                            <div className="p-y-10 text-center">لطفا ایمیل خود را وارد کنید</div>
                                            <div className="login__field p-t-10 p-b-20 m-b-10 pos-rel">
                                                <input
                                                    autoComplete="off"
                                                    value={this.state.password}
                                                    onChange={(e) => this.setState({ password: e.target.value })}
                                                    type="password"
                                                    className="login__input input"
                                                    placeholder="رمزعبور"
                                                />
                                                <span className="red d-block register-message f-12">
                                                    رمز عبور باید شامل حروف کوچک و بزرگ و حداقل یک علامت (/%*&!) باشد!
                                                </span>
                                            </div>
                                            <div className="login__field p-y-10">
                                                <input
                                                    autoComplete="off"
                                                    value={this.state.repeatPassword}
                                                    onChange={(e) => this.setState({ repeatPassword: e.target.value })}
                                                    type="password"
                                                    className="login__input input"
                                                    placeholder="تکرار رمزعبور"
                                                />
                                            </div>

                                            <div className="d-flex ai-center jc-center">
                                                {this.state.loading ? (
                                                    <button className="button login__submit loading-btn">
                                                        <span className="button__text">صبر کنید ...</span>
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="button login__submit"
                                                        onClick={() => {
                                                            if (
                                                                this.state.password !== this.state.repeatPassword ||
                                                                this.state.password == null
                                                            ) {
                                                                toastr.warning("تکرار رمز اشتباه است");
                                                                return;
                                                            }
                                                            this.resetPassword();
                                                        }}
                                                    >
                                                        <span className="button__text">تایید</span>
                                                    </button>
                                                )}
                                            </div>
                                            <div className="p-t-15">
                                                <Link className="p-b-30 p-t-5 f-12 d-block p-r-10 black text-center" to="Login">
                                                    <span className="white d-inblock p-r-5 default-color f-14">وارد شوید</span>
                                                </Link>
                                            </div>
                                            <Link className="p-b-30 p-t-5 f-12 d-block p-r-10 black text-center" to="Register">
                                                کاربر جدید هستید؟
                                                <span className="white d-inblock p-r-5 default-color f-14">ثبت نام</span>
                                            </Link>
                                        </div>
                                        <div className="social-login">
                                            {/* <Link className="p-t-15 f-14 d-block default-color" to="Register">
                                                <span className="white d-inblock f-16">ثبت نام </span>
                                            </Link>
                                            <span className="white d-inblock f-16">فراموشی رمزعبور </span> */}
                                            {/* <div className="social-icons">
                                    <a className="social-login__icon fab fa-instagram">
                                        <span></span>
                                    </a>
                                    <a href="#" className="social-login__icon fab fa-facebook"></a>
                                    <a href="#" className="social-login__icon fab fa-twitter"></a>
                                </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="lg-7 p-0 d-flex">
                                    <div className="login-bg-img d-flex ai-center">
                                        <img src="/images/login.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(ResetPassword);
