import React from "react";
import Button from "./Button";

class Select extends React.Component {
    state = {isMenuOpen: false, selectedOption: this.props.selectedOptionId ?? this.props.value};

    render() {
        const renderOptions = () => {
            if (this.props.selectOptions == null) {
                return <div>هیچ موردی وجود ندارد</div>;
            } else {
                return this.props.selectOptions.map((el) => (
                    <div
                        key={el.id}
                        className="select-option pointer"
                        onClick={() => {
                            const onChange = this.props.selectChange ?? this.props.onChange;
                            onChange(el.id, this.state.selectedOption);
                            this.setState({selectedOption: el.id, isMenuOpen: false});
                        }}
                    >
                        {el.name}
                    </div>
                ));
            }
        };
        const renderSelectedOption = () => {
            let element = this.props.selectOptions.find((c) => c.id === this.state.selectedOption);
            if (element) {
                return element.name;
            } else {
                return "انتخاب نشده";
            }
        };
        return (
            <div className={`select-button pos-rel ${this.props.className}`}>
                <div onClick={() => this.setState({isMenuOpen: !this.state.isMenuOpen})}>
                    <Button>
                        {this.props.children} {renderSelectedOption()}
                        <span className="iconly-brokenArrow---Down-2 p-r-5"></span>
                    </Button>
                </div>
                <div className={`select-options ${this.state.isMenuOpen ? "" : "d-none"}`}>
                    {this.state.isMenuOpen ? renderOptions() : null}
                </div>
            </div>
        );
    }
}

export default Select;
