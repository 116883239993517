import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../Elements/Loading";
import TopMenu from "./../Layout/TopMenu";
import DropDown from "./../Elements/DropDown";
import ReactSelect from "../Elements/ReactSelect";
import HorizontalTaskManagement from "./../PageParts/Post/HorizontalTaskManagement";
import toastr from "toastr";
import FileUploader from "../Elements/FileUploader/FileUploader";
import callServer from "./../../axios/callServer";
import * as moment from "jalali-moment";
import Activity from "./../PageParts/Post/Activity";
import ConfirmBox from "../PageParts/Post/ConfirmBox";
import Notificaions from "../PageParts/Post/Notifications";
import CommentBox from "../PageParts/Post/CommentBox";
import ToPersianDate from "./../Elements/ToPersianDate";
import NoDataFound from "./../PageParts/Common/NoDataFound";
import PostItem from "./../PageParts/Common/PostItem";
import DateTimePicker from "./../Elements/DateTimePicker/DateTimePicker";

class EditPost extends Component {
    state = {
        loading: true,
        post: null,
        name: "",
        description: "",
        postProjectId: null,
        dueDate: new Date(),
        socialNetworkId: null,
        postStatusId: null,
        projectId: null,
        postStatuses: [],
        socialMedias: [],
        projectUsers: [],
        descriptionEditors: [],
        mediaEditors: [],
        followers: [],
        confirmers: [],
        tags: [],
        urls: [],
        activities: [],
        currentUserId: "",
        isPostAdmin: false,
        comments: null,
    };

    async componentDidMount() {
        this.setState({
            loading: true,
            post: null,
            name: "",
            description: "",
            postProjectId: null,
            dueDate: new Date(),
            socialNetworkId: null,
            postStatusId: null,
            projectId: null,
            postStatuses: [],
            socialMedias: [],
            projectUsers: [],
            descriptionEditors: [],
            mediaEditors: [],
            followers: [],
            confirmers: [],
            tags: [],
            urls: [],
            activities: [],
            currentUserId: "",
            isPostAdmin: false,
            comments: null,
        });
        const data = await callServer("get", `/posts/${this.props.match.params.id}`, this.props.token);
        if (data == null) {
            this.setState({ loading: false });
            return;
        }
        const post = data.post;
        if (post) {
            let date = new ToPersianDate().getDateObject(post.dueDate);
            this.setState({
                post: post,
                name: post.name,
                followers: data.followers,
                confirmers: data.confirmers,
                mediaEditors: data.mediaEditors,
                descriptionEditors: data.descriptionEditors,
                projectUsers: data.projectUsers,
                socialNetworkId: post.socialNetworkId,
                socialMedias: data.socialNetworks.map((el) => {
                    return { value: el.id, label: el.name };
                }),
                postStatuses: data.status.map((el) => {
                    return { value: el.id, label: el.name };
                }),
                tags: post.postTags.map((el) => {
                    return { value: el.tag.id, label: el.tag.title };
                }),
                urls: post.mediaCollections.map((el) => {
                    return { id: el.id, url: el.url };
                }),
                postStatusId: post.statusId,
                description: post.description,
                dueDate: date,
                files: this.getStartFile(post.mediaCollections),
                activities: data.responseActivities,
                currentUserId: data.currentUserId,
                isPostAdmin: data.isPostAdmin,
            });
        }

        this.setState({ loading: false });

        const activity = await callServer("get", `/posts/activity/${this.props.match.params.id}`, this.props.token);
        if (!activity) return;
        this.setState({ activities: activity });

        await this.reloadComments();
    }

    reloadComments = async () => {
        const comments = await callServer("get", `/comments/GetByPost/${this.props.match.params.id}`, this.props.token);
        if (!comments) return;
        this.setState({ comments });
    };
    getStartFile = (imagesArray) => {
        return imagesArray.map((item) => {
            return {
                source: item.url,
                options: {
                    type: "local",
                    metadata: {
                        poster: item.url,
                    },
                },
            };
        });
    };
    handleEditPost = async () => {
        if (this.state.name.length < 1) return toastr.error("نام الزامی است!");
        let newPost = {
            id: this.state.post.id,
            name: this.state.name,
            statusId: this.state.postStatusId,
            dueDate: this.state.dueDate,
            socialNetworkId: this.state.socialNetworkId,
            description: this.state.description,
            postTags: this.state.tags.map((t) => {
                if (Number(t.value) > 0)
                    return {
                        tag: {
                            title: t.label,
                            id: t.value,
                        },
                    };
                else if (Number(t) > 0)
                    return {
                        tag: {
                            id: t,
                        },
                    };
                return {
                    tag: {
                        title: t,
                    },
                };
            }),
            mediaCollections: this.state.urls.map((u) => {
                return {
                    url: u.url,
                    id: u.id,
                };
            }),
            participates: [
                ...this.state.confirmers.map((el) => {
                    return {
                        userId: el.id,
                        participateTypeId: 4,
                        isDone: el.isDone,
                    };
                }),
                ...this.state.followers.map((el) => {
                    return {
                        userId: el.id,
                        participateTypeId: 3,
                        isDone: el.isDone,
                    };
                }),
                ...this.state.mediaEditors.map((el) => {
                    return {
                        userId: el.id,
                        participateTypeId: 2,
                        isDone: el.isDone,
                    };
                }),
                ...this.state.descriptionEditors.map((el) => {
                    return {
                        userId: el.id,
                        participateTypeId: 1,
                        isDone: el.isDone,
                    };
                }),
            ],
        };
        // var participateTypeId = requestChangePost.ParticipateType == "Follower" ? 3 :
        // requestChangePost.ParticipateType == "DescriptionEditor" ? 1 : 2;
        //console.log(newPost);
        this.setState({ loading: true });
        const result = await callServer("post", "/Posts/Edit", this.props.token, newPost);
        //this.props.history.push("/panel");
        if (result == null) return;
        window.history.back();
        //this.componentDidMount();
        //console.log(result);
    };

    handlePreviewPost = () => {
        return {
            id: this.state.post.id,
            name: this.state.name,
            statusId: this.state.postStatusId,
            dueDate: this.state.dueDate,
            socialNetworkId: this.state.socialNetworkId,
            description: this.state.description,
            postTags: this.state.tags.map((t) => {
                if (Number(t.value) > 0)
                    return {
                        tag: {
                            title: t.label,
                            id: t.value,
                        },
                    };
                else if (Number(t) > 0)
                    return {
                        tag: {
                            id: t,
                        },
                    };
                return {
                    tag: {
                        title: t,
                    },
                };
            }),
            mediaCollections: this.state.urls.map((u) => {
                return {
                    url: u.url,
                    id: u.id,
                };
            }),
        };
    };

    handleArchivePost = async () => {
        if (!window.confirm("آیا از آرشیو پست امینان دارید؟")) {
            return;
        }
        this.setState({ loading: true });
        const result = await callServer("get", `/Posts/ArchivePost/${this.state.post.id}`, this.props.token);
        this.setState({ loading: false });
        if (result == null) return;
        this.props.history.push("/panel");
    };

    render() {
        if (this.state.loading) return <Loading />;
        else if (this.state.post == null)
            return (
                <div className="main-content pos-rel">
                    <div className="m-x-40">
                        <div className="container">
                            <TopMenu hasBackButton={true} />
                            <NoDataFound />
                        </div>
                    </div>
                </div>
            );
        return (
            <div className="main-content pos-rel">
                <div className="m-x-40">
                    <div className="container">
                        <TopMenu hasBackButton={true} projectId={this.state.post.projectId} />
                        <div className="back-smoke p-y-50 position-relative row">
                            <div className="lg-3 order-lg-0 order-1 post-sidebar">
                                <div className="post-confirm-container">
                                    {this.state.confirmers.map((el) => {
                                        if (el.id == this.state.currentUserId)
                                            return (
                                                <ConfirmBox
                                                    key={el.id + el.firstName + el.lastName}
                                                    title="شما جزو تایید کنندگان این پست هستید"
                                                    checkBoxLabel="تایید"
                                                    value={el.isDone}
                                                    onChange={(val) =>
                                                        this.setState({
                                                            confirmers: this.state.confirmers.map((p) => {
                                                                if (p.id == this.state.currentUserId) {
                                                                    p.isDone = val;
                                                                    return p;
                                                                } else {
                                                                    return p;
                                                                }
                                                            }),
                                                        })
                                                    }
                                                />
                                            );
                                    })}

                                    {this.state.mediaEditors.map((el) => {
                                        if (el.id == this.state.currentUserId)
                                            return (
                                                <ConfirmBox
                                                    key={el.id + el.firstName + el.lastName}
                                                    title="شما جزو گرافیست های این پست هستید"
                                                    checkBoxLabel="کامل شد"
                                                    value={el.isDone}
                                                    onChange={(val) =>
                                                        this.setState({
                                                            mediaEditors: this.state.mediaEditors.map((p) => {
                                                                if (p.id == this.state.currentUserId) {
                                                                    p.isDone = val;
                                                                    return p;
                                                                } else {
                                                                    return p;
                                                                }
                                                            }),
                                                        })
                                                    }
                                                />
                                            );
                                    })}

                                    {this.state.descriptionEditors.map((el) => {
                                        if (el.id == this.state.currentUserId)
                                            return (
                                                <ConfirmBox
                                                    key={el.id + el.firstName + el.lastName}
                                                    title="شما جزو نویسندگان این پست هستید"
                                                    checkBoxLabel="کامل شد"
                                                    value={el.isDone}
                                                    onChange={(val) =>
                                                        this.setState({
                                                            descriptionEditors: this.state.descriptionEditors.map((p) => {
                                                                if (p.id == this.state.currentUserId) {
                                                                    p.isDone = val;
                                                                    return p;
                                                                } else {
                                                                    return p;
                                                                }
                                                            }),
                                                        })
                                                    }
                                                />
                                            );
                                    })}
                                </div>

                                <Notificaions />
                                <div className="publish-segment">
                                    <p>
                                        <span>تاریخ انتشار</span>
                                        <span>{new ToPersianDate().toFormat(this.state.dueDate, "jYYYY/jM/jD HH:mm")}</span>
                                    </p>
                                    {/* <p>
                                        <span>تاریخ منتشر شده</span>
                                        <span>1400/02/05</span>
                                    </p> */}
                                </div>
                                <div className="people-in">
                                    <HorizontalTaskManagement
                                        title="افراد حاضر در پروژه"
                                        isProjectSelected={this.state.projectUsers.length > 0}
                                        users={this.state.projectUsers}
                                        currentUsers={this.state.followers}
                                        currentUsersChange={(users) => {
                                            this.setState({ followers: users });
                                        }}
                                        type="Follower"
                                        titleClassName="f-16"
                                    />
                                </div>
                                {this.state.isPostAdmin ? (
                                    <div className="people-in">
                                        <HorizontalTaskManagement
                                            title="تایید کنندگان"
                                            isProjectSelected={this.state.projectUsers.length > 0}
                                            users={this.state.projectUsers}
                                            currentUsers={this.state.confirmers}
                                            currentUsersChange={(users) => {
                                                this.setState({ confirmers: users });
                                            }}
                                            type="Follower"
                                            titleClassName="f-16"
                                        />
                                    </div>
                                ) : null}
                                {this.state.confirmers.length > 0 ? (
                                    <div className="confirmation">
                                        <div className="heading">
                                            <span>تاییدیه ها</span>
                                        </div>
                                        <div className="people-to-confirm">
                                            {this.state.confirmers.map((el) => {
                                                return (
                                                    <label key={el.id + el.firstName + el.lastName} className="cutsom-checkbox-1">
                                                        {el.firstName} {el.lastName}
                                                        <input disabled checked={el.isDone} type="checkbox" />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ) : null}
                                {this.state.descriptionEditors.length > 0 ? (
                                    <div className="confirmation">
                                        <div className="heading">
                                            <span>نویسندگان</span>
                                        </div>
                                        <div className="people-to-confirm">
                                            {this.state.descriptionEditors.map((el) => {
                                                return (
                                                    <label key={el.id + el.firstName + el.lastName} className="cutsom-checkbox-1">
                                                        {el.firstName} {el.lastName}
                                                        <input disabled checked={el.isDone} type="checkbox" />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ) : null}
                                {this.state.mediaEditors.length > 0 ? (
                                    <div className="confirmation">
                                        <div className="heading">
                                            <span>گرافیست ها</span>
                                        </div>
                                        <div className="people-to-confirm">
                                            {this.state.mediaEditors.map((el) => {
                                                return (
                                                    <label key={el.id + el.firstName + el.lastName} className="cutsom-checkbox-1">
                                                        {el.firstName} {el.lastName}
                                                        <input disabled checked={el.isDone} type="checkbox" />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ) : null}

                                <PostItem isPagePreview className="h-auto-im" post={this.handlePreviewPost()} />

                                <a className="archieve-btn d-block m-t-30 text-center w-100" onClick={this.handleArchivePost}>
                                    آرشیو
                                </a>
                            </div>
                            <div className="lg-9 order-lg-1 order-2 post-overview">
                                <div className="post-desc">
                                    <div className="row">
                                        <div className="lg-4 p-b-15">
                                            <label htmlFor="PostTitle">عنوان پست</label>
                                            <input
                                                value={this.state.name}
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                                className="input f-16 m-t-5"
                                                id="PostTitle"
                                            />
                                        </div>
                                        <div className="lg-8 p-b-15">
                                            <label htmlFor="ProjectTitle">پروژه</label>
                                            <ReactSelect
                                                isDisabled
                                                defaultValue={[
                                                    {
                                                        value: this.state.post.project.id,
                                                        label: this.state.post.project.name,
                                                    },
                                                ]}
                                                options={[
                                                    {
                                                        value: this.state.post.project.id,
                                                        label: this.state.post.project.name,
                                                    },
                                                ]}
                                                className="m-t-5"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="lg-4">
                                            <label htmlFor="PostDate">تاریخ پست</label>
                                            <div>
                                                <DateTimePicker
                                                    inputClass="input text-center m-t-5 f-16"
                                                    value={this.state.dueDate}
                                                    onChange={(value) => {
                                                        this.setState({ dueDate: value.toDate() });
                                                        console.log(value.toDate());
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="lg-3">
                                            <label htmlFor="PostPlatform">پلتفرم</label>
                                            <ReactSelect
                                                onChange={(item) => {
                                                    this.setState({ socialNetworkId: item.value });
                                                }}
                                                defaultValue={this.state.socialMedias.filter(
                                                    (el) => el.value === this.state.socialNetworkId
                                                )}
                                                options={this.state.socialMedias}
                                                value={this.state.socialNetworkId}
                                                noOptionsMessage="ابتدا یک پروژه انتخاب کنید"
                                                className="m-t-5"
                                            />
                                        </div>
                                        <div className="lg-3">
                                            <label htmlFor="PostStatus">وضعیت پست</label>
                                            <ReactSelect
                                                options={this.state.postStatuses}
                                                value={this.state.postStatusId}
                                                defaultValue={this.state.postStatuses.filter(
                                                    (el) => el.value === this.state.postStatusId
                                                )}
                                                onChange={(item) => {
                                                    this.setState({ postStatusId: item.value });
                                                }}
                                                className="m-t-5"
                                            />
                                        </div>

                                        <div class="ai-end lg-2 m-t-0 p-0 submit-archieve text-left">
                                            <a
                                                onClick={this.handleEditPost}
                                                class="m-l-15 m-r-15 m-t-10 submit-btn text-center w-100 pos-rel"
                                                style={{ padding: "8px", top: "1px" }}
                                            >
                                                ثبت
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <DropDown name="متن پست" isOpen={false} className="p-0 post-text" titleClassName="text-bold">
                                    <div className="post-text-body row p-15 p-b-30">
                                        <div className="md-6">
                                            <p>متن پست</p>
                                            <textarea
                                                value={this.state.description}
                                                onChange={(e) => this.setState({ description: e.target.value })}
                                                className="w-100 m-b-20 textarea post-textarea m-t-10"
                                            ></textarea>
                                        </div>
                                        <div className="md-6">
                                            <div className="responsible-people p-t-5 p-b-15">
                                                <HorizontalTaskManagement
                                                    title="افراد مسئول"
                                                    isProjectSelected={this.state.projectUsers.length > 0}
                                                    users={this.state.projectUsers}
                                                    currentUsers={this.state.descriptionEditors}
                                                    currentUsersChange={(users) => {
                                                        this.setState({ descriptionEditors: users });
                                                    }}
                                                    type="Follower"
                                                    titleClassName="f-16"
                                                />
                                            </div>
                                            <div className="">
                                                <label>هشتگ و تگ</label>
                                                <ReactSelect
                                                    tagMannager
                                                    onChange={(items) => {
                                                        this.setState({ tags: items.map((i) => i.value) });
                                                    }}
                                                    defaultValue={this.state.tags}
                                                    options={this.state.tags}
                                                    isCreatable
                                                    isMulti
                                                    className="m-t-5"
                                                />
                                            </div>
                                        </div>

                                        <div className="jc-end lg-12 m-t-0 p-0 submit-archieve text-left">
                                            <a style={{ padding: "10px 30px" }} onClick={this.handleEditPost} className="submit-btn">
                                                ثبت
                                            </a>
                                        </div>

                                        <div className="md-12">
                                            <CommentBox
                                                comments={this.state.comments}
                                                commentTypeId={1}
                                                postId={this.props.match.params.id}
                                                reloadComments={async () => await this.reloadComments()}
                                            />
                                        </div>
                                    </div>
                                </DropDown>

                                <DropDown name="مدیا تصویری" isOpen={false} className="p-0 post-media" titleClassName="text-bold">
                                    <div className="p-15 p-b-30">
                                        <div className="row">
                                            <div className="lg-8">
                                                <div className="horizontal-filepond">
                                                    <FileUploader
                                                        allowMultiple
                                                        name="file"
                                                        server={{
                                                            process: {
                                                                url: `/ImageUploader`,
                                                                headers: {
                                                                    Authorization: `Bearer ${this.props.token}`,
                                                                },
                                                                onload: (par) => {
                                                                    par = JSON.parse(par);
                                                                    if (par.imageUrl) {
                                                                        this.setState({
                                                                            urls: [
                                                                                ...this.state.urls,
                                                                                {
                                                                                    url: par.imageUrl,
                                                                                },
                                                                            ],
                                                                        });
                                                                    }
                                                                    return par.imageUrl;
                                                                },
                                                            },
                                                            revert: async (par, load, a, b, c) => {
                                                                try {
                                                                    // remove
                                                                    this.setState({
                                                                        urls: this.state.urls.filter((u) => u.url != par),
                                                                    });
                                                                    load();
                                                                } catch {
                                                                    toastr.error("خطا در برقراری ارتباط با سرور");
                                                                }
                                                            },
                                                            remove: async (par, load) => {
                                                                try {
                                                                    //remove
                                                                    this.setState({
                                                                        urls: this.state.urls.filter((u) => u.url != par),
                                                                    });
                                                                    console.log(par);
                                                                    load();
                                                                } catch {
                                                                    toastr.error("خطا در برقراری ارتباط با سرور");
                                                                }
                                                            },
                                                            load: async (par, load) => {
                                                                const response = await fetch(par);
                                                                const fileName = [...response.url.split("/")].pop();
                                                                const blob = await response.blob();
                                                                const file = new File([blob], fileName, { type: blob.type });
                                                                console.log(file);

                                                                load(file);
                                                            },
                                                        }}
                                                        files={this.state.files}
                                                        onChange={({ files }) => {
                                                            this.setState({ files });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="lg-4">
                                                <div className="responsible-people p-t-5 p-b-15">
                                                    <HorizontalTaskManagement
                                                        title="افراد مسئول"
                                                        isProjectSelected={this.state.projectUsers.length > 0}
                                                        users={this.state.projectUsers}
                                                        currentUsers={this.state.mediaEditors}
                                                        currentUsersChange={(users) => {
                                                            this.setState({ mediaEditors: users });
                                                        }}
                                                        type="Follower"
                                                        titleClassName="f-16"
                                                    />
                                                </div>
                                            </div>
                                            <div className="jc-end lg-12 m-t-0 p-0 submit-archieve text-left">
                                                <a onClick={this.handleEditPost} className="submit-btn">
                                                    ثبت
                                                </a>
                                            </div>
                                            <div className="md-12 p-t-20">
                                                <CommentBox
                                                    comments={this.state.comments}
                                                    commentTypeId={2}
                                                    postId={this.props.match.params.id}
                                                    reloadComments={async () => await this.reloadComments()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </DropDown>

                                <DropDown name="رویداد های پست" isOpen={false} className="p-0 post-media" titleClassName="text-bold">
                                    <div className="p-x-30 p-b-20">
                                        <Activity activities={this.state.activities} />
                                    </div>
                                </DropDown>

                                <DropDown name="نظرات پست" isOpen={false} className="p-0 post-media" titleClassName="text-bold">
                                    <div className="p-x-30 p-b-20">
                                        <div className="row">
                                            <div className="md-12">
                                                <CommentBox
                                                    dontShowTitle
                                                    comments={this.state.comments}
                                                    commentTypeId={3}
                                                    postId={this.props.match.params.id}
                                                    reloadComments={async () => await this.reloadComments()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </DropDown>
                                {/* <div className="post-add">
                                    <a href="#">
                                        <i className="zmdi zmdi-plus"></i>
                                    </a>
                                </div> */}
                                <div className="submit-archieve">
                                    <a onClick={this.handleEditPost} className="submit-btn">
                                        ثبت
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { token: state.auth.token };
};
export default connect(mapStateToProps)(EditPost);
