import React, { Component } from "react";
import Loading from "../../Elements/Loading";
import ActivityItem from "./ActivityItem";
class Activity extends Component {
    state = {};

    renderActivities = () => {
        return this.props.activities?.map((ac) => {
            return <ActivityItem activity={ac} />;
        });
    };
    render() {
        if (!this.props.activities)
            return (
                <div className="activity-loading">
                    <Loading />
                </div>
            );
        return <div>{this.renderActivities()}</div>;
    }
}

export default Activity;
