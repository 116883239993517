import React, { Component } from "react";
import callServer from "./../../../../axios/callServer";
import { connect } from "react-redux";
import Loading from "../../../Elements/Loading";

class PostSummary extends Component {
    state = { isReady: false, SentPosts: 0, waitingPosts: 0, draftPosts: 0, readyPosts: 0, failurePosts: 0 };
    async componentDidMount() {
        const result = await callServer("get", "/Posts/Summary", this.props.token);
        if (result == null) return;
        this.setState({
            isReady: true,
            sentPosts: result.sentPosts,
            waitingPosts: result.waitingPosts,
            draftPosts: result.draftPosts,
            readyPosts: result.readyPosts,
            failurePosts: result.failurePosts,
        });
    }
    render() {
        return (
            <div className="projects">
                <h2>خلاصه پست‌ها</h2>
                <div className="projects-repeater">
                    {this.state.isReady ? (
                        <div>
                            <div className="each-post-summary published">
                                <span>منتشر شده</span>
                                <span>{this.state.sentPosts} پست</span>
                            </div>
                            <div className="each-post-summary waiting">
                                <span>در انتظار تایید</span>
                                <span>{this.state.waitingPosts} پست</span>
                            </div>
                            <div className="each-post-summary draft">
                                <span>پیشنویس</span>
                                <span>{this.state.draftPosts} پست</span>
                            </div>
                            <div className="each-post-summary queue">
                                <span>در صف انتشار</span>
                                <span>{this.state.readyPosts} پست</span>
                            </div>
                            <div className="each-post-summary rejected">
                                <span>مردود</span>
                                <span>{this.state.failurePosts} پست</span>
                            </div>
                        </div>
                    ) : (
                        <div className="pos-rel p-t-100">
                            <Loading />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token, projectId: state.project.projectId };
};
export default connect(mapStateToProps)(PostSummary);
