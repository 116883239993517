import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_en from "react-date-object/locales/persian_fa";
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import "react-multi-date-picker/styles/colors/purple.css";
import "react-multi-date-picker/styles/colors/analog_time_picker_purple.css";
import "./DateTimePicker.scss";
import React, { Component } from "react";

export default class DateTimePicker extends Component {
    render() {
        return (
            <DatePicker
                calendar={persian}
                locale={persian_en}
                value={this.props.value}
                multiple={this.props.multiple ?? false}
                onChange={(val) => this.props.onChange(val)}
                className="purple"
                calendarPosition="left"
                weekDays={["ش", "ی", "د", "س", "چ", "پ", "ج"]}
                shadow
                inputClass={this.props.inputClass}
                placeholder={this.props.placeholder}
                inputMode={this.props.inputMode ?? true}
                analogTimePicker
                format="YYYY/MM/DD HH:mm"
                plugins={[<TimePicker hideSeconds position="left" />]}
            />
        );
    }
}
