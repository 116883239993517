import React, {Component} from "react";
import {connect} from "react-redux";
import PlatformProjects from "../PageParts/Project/PlatformProjects";
import DateProjects from "../PageParts/Project/DateProjects";
import TopMenu from "../Layout/TopMenu";
import Loading from "../Elements/Loading";
import callServer from "../../axios/callServer";
import ArchivedPosts from "../PageParts/Project/ArchivedPosts";

class Project extends Component {
    async componentDidMount() {
        const result = await callServer("get", `/Posts/Project/${this.props.match.params.id}`, this.props.token);
        if (result == null) return;
        this.setState({posts: result, isLoading: false});
        console.log(result);
    }

    state = {isPlatformBased: true, isDateBased: false, isArchivedBased: false, posts: [], isLoading: true};

    handleContent() {
        if (this.state.isPlatformBased) {
            return <PlatformProjects posts={this.state.posts} projectId={this.props.match.params.id}/>;
        }
        if (this.state.isDateBased) {
            return <DateProjects posts={this.state.posts}/>;
        }
        if (this.state.isArchivedBased) {
            return <ArchivedPosts projectId={this.props.match.params.id}/>;
        }
    }

    render() {
        if (this.state.isLoading) return <Loading/>;
        return (
            <div className="main-content pos-rel">
                <div className="m-x-40">
                    <TopMenu hasBackButton={true} projectId={this.props.match.params.id}/>
                    <div className="back-smoke">
                        <div className="d-flex jc-between ai-center p-x-15">
                            <div className="p-y-15 p-x-15 d-flex ai-center">
                                <div className="p-l-10">نمایش بر اساس</div>
                                <div className="default-box d-flex ai-center">
                                    <div className="p-x-10">
                                        <label className="cutsom-checkbox-1 m-b-0" onClick={(e) => this.setState({
                                            isPlatformBased: true,
                                            isDateBased: false,
                                            isArchivedBased: false
                                        })}>
                                            پلتفرم
                                            <input
                                                type="checkbox"
                                                checked={this.state.isPlatformBased}

                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="p-x-10">
                                        <label className="cutsom-checkbox-1 m-b-0" onClick={(e) => this.setState({
                                            isPlatformBased: false,
                                            isDateBased: true,
                                            isArchivedBased: false
                                        })}>
                                            تاریخ
                                            <input
                                                type="checkbox"
                                                checked={this.state.isDateBased}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="p-x-10">
                                        <label className="cutsom-checkbox-1 m-b-0" onClick={(e) => this.setState({
                                            isPlatformBased: false,
                                            isDateBased: false,
                                            isArchivedBased: true
                                        })}>
                                            آرشیو ها
                                            <input
                                                type="checkbox"
                                                checked={this.state.isArchivedBased}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img className="mini-logo" src={this.props.project?.imageUrl} alt=""/>
                            </div>
                        </div>
                        {
                            this.handleContent()
                        }

                    </div>
                </div>
            </div>
        );
    }
}

const
    mapStateToProps = (state) => {
        return {token: state.auth.token, project: state.project.project};
    };
export default connect(mapStateToProps)

(
    Project
)
;
