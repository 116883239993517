import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";

class Slider extends Component {
    render() {
        return (
            <div style={{ direction: "initial" }}>
                <Carousel dynamicHeight emulateTouch showThumbs={false} showIndicators={false} showStatus={false}>
                    {this.props.children}
                </Carousel>
            </div>
        );
    }
}

export default Slider;
