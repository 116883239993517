import React from "react";

class CheckBox extends React.Component {
    render() {
        return (
            <div className={this.props.className}>
                <label className="cutsom-checkbox-1 m-b-0">
                    {this.props.label ?? this.props.children}
                    <input
                        type="checkbox"
                        checked={this.props.value}
                        onChange={(e) => this.props.onChange(e.target.checked)}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
        );
    }
}

export default CheckBox;
