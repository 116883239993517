import moment from "jalali-moment";
import React, { Component } from "react";
import callServer from "../../../../axios/callServer";
import EventsReadMore from "./EventsReadMore";

export default class JalaliDay extends Component {
    state = {
        dayEvents: [],
        dayContents: [],
        date: moment(this.props.day.date).locale("fa"),
    };

    handleEvents = async () => {
        let api = this.props.api;
        api = api.replace("{Year}", this.state.date.jYear());
        api = api.replace("{Month}", this.state.date.format("MM"));
        api = api.replace("{Day}", this.state.date.jDate());
        const result = await callServer("get-notoken", api);
        if (result == null) return;

        this.setState({
            dayEvents: result,
        });
    };

    async componentDidMount() {
        await this.handleEvents();
        this.props.contents?.forEach((cur) => {
            cur.date = cur.date ?? cur.dueDate ?? cur.createdOn;
            if (moment(cur.date).locale("fa").isSame(this.state.date, "jDay")) {
                this.setState({
                    dayContents: [...this.state.dayContents, cur],
                });
            }
        });
    }

    handleClick = () => {
        this.props.onClick(this.state.date, this.state.dayContents, this.state.dayEvents);
    };

    renderEvnets = () => {
        return this.state.dayEvents?.events?.map((cur) => {
            return <div className="jc-event-item">{cur.description}</div>;
        });
    };

    renderSingleEvnet = () => {
        if (this.state.dayEvents?.is_holiday && this.state.dayEvents?.events[0]?.description?.trim() !== "جمعه") {
            return (
                <div>
                    <EventsReadMore size={15}>{this.state.dayEvents?.events[0]?.description}</EventsReadMore>
                </div>
            );
        }
    };

    renderContent = () => {
        return this.state.dayContents?.map((cur) => {
            return (
                <div className="jc-content-item" style={{ background: cur.color }}>
                    <div className="jc-content-item-image">{cur.image ? <img alt="" src={cur.image} /> : null}</div>
                    <div className="jc-content-item-title">{cur.title ?? cur.name ?? cur.description}</div>
                </div>
            );
        });
    };

    renderDay = () => {
        if (this.props.dayHtml) {
            return this.props.dayHtml(this.state.date, this.state.dayContents, this.state.dayEvents);
        } else {
            return (
                <div className="jc-day-box">
                    <div className="jc-day-box-header">
                        <div className="jc-day-date">{this.state.date?.format("DD")}</div>
                        <div className="jc-day-single-event">{this.renderSingleEvnet()}</div>
                    </div>
                    <div className="jc-day-events">{this.renderEvnets()}</div>
                    <div className="jc-day-content">{this.renderContent()}</div>
                </div>
            );
        }
    };

    render() {
        return (
            <div
                className={`jc-day ${this.props.day?.isActive ? "active" : "not-active"} ${
                    this.state.dayEvents?.is_holiday ? "holiday" : ""
                }`}
                style={this.props.day?.isActive ? {} : { opacity: "0.5" }}
            >
                <span className="jc-day-link" onClick={this.handleClick}></span>
                {this.renderDay()}
            </div>
        );
    }
}
