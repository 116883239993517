import React, { Component } from "react";
import { connect } from "react-redux";
import PostItem from "../Common/PostItem";

class DateProject extends Component {
    async componentDidMount() {}
    state = { posts: [], now: null };
    render() {
        return (
            <div className="container post-preview-container">
                <div className="back-smoke p-y-50 position-relative row">
                    {this.props.posts?.map((p) => (
                        <div className="lg-3 p-b-15">
                            <PostItem post={p} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token };
};
export default connect(mapStateToProps)(DateProject);
