import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Button from "../../Elements/Button";
import toastr from "toastr";
import axios from "axios";
import { connect } from "react-redux";
import DropDown from "./../../Elements/DropDown";

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
    }
    state = { oldPassword: "", password: "", repeatPassword: "" };
    changePassword = async () => {
        if (this.state.password !== this.state.repeatPassword) {
            toastr.warning("تکرار رمز مطابقت ندارد!");
            return;
        }
        if (this.state.password == null || this.state.repeatPassword == null || this.state.password == null) {
            toastr.warning("همه موارد را پر کنید!");
            return;
        }
        const body = {
            oldPassword: this.state.oldPassword,
            newPassword: this.state.repeatPassword,
        };
        await axios.post(`/Users/ChangePassword`, body, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.props.token}`,
            },
        });
        toastr.success("با موفقیت انچام شد!");
        this.setState({ oldPassword: "", password: "", repeatPassword: "" });
    };
    render() {
        return (
            <DropDown name="ویرایش رمز عبور" isOpen={false} className="p-0 post-text" titleClassName="text-bold">
                <div className="dir-rtl p-x-30 p-b-15">
                    <div className="row">
                        <div className="lg-6">
                            <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                                <p>رمزعبور قبلی :</p>
                                <div className="grow-1 p-r-10">
                                    <input
                                        type="password"
                                        onChange={(e) => this.setState({ oldPassword: e.target.value })}
                                        value={this.state.oldPassword}
                                        className="input text-left"
                                    />
                                    {this.validator.message("oldPassword", this.state.oldPassword, "required|alpha")}
                                </div>
                            </div>
                        </div>
                        <div className="lg-6">
                            <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                                <p>رمزعبور جدید :</p>
                                <div className="grow-1 p-r-10">
                                    <input
                                        type="password"
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                        value={this.state.password}
                                        className="input text-left"
                                    />
                                    {this.validator.message("password", this.state.password, "required|alpha")}
                                </div>
                            </div>
                        </div>
                        <div className="lg-6 min-h-1px"></div>
                        <div className="lg-6">
                            <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                                <p>تکرار رمزعبور جدید :</p>
                                <div className="grow-1 p-r-10">
                                    <input
                                        type="password"
                                        onChange={(e) => this.setState({ repeatPassword: e.target.value })}
                                        value={this.state.repeatPassword}
                                        className="input text-left"
                                    />
                                    {this.validator.message("repeatPassword", this.state.repeatPassword, "required|alpha")}
                                </div>
                            </div>
                        </div>
                        <div className="lg-12 text-center p-t-15">
                            <span onClick={this.changePassword}>
                                <Button>تغییر رمز </Button>
                            </span>
                        </div>
                    </div>
                </div>
            </DropDown>
        );
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token };
};
export default connect(mapStateToProps)(ChangePassword);
