import React, {Component} from "react";
import callServer from "./../../../../axios/callServer";
import {connect} from "react-redux";
import toastr from "toastr";
import {selectProject} from "./../../../../actions/index";
import FileUploader from "./../../../Elements/FileUploader/FileUploader";
import {withRouter} from "react-router-dom";
import CheckBox from "../../../Elements/CheckBox";
import Loading from "../../../Elements/Loading";

class AddProject extends Component {
    buildNewProject = async () => {
        if (this.state.name == "") return;
        this.setState({loading: true});
        const result = await callServer("post", "/Projects/New", this.props.token, {
            name: this.state.name,
            newImageUrl: this.state.newImageUrl,
            defaultSocialNetwork: {
                hasInstagram: this.state.hasInstagram,
                hasTelegram: this.state.hasTelegram,
                hasTwitter: this.state.hasTwitter,
                hasLinkedin: this.state.hasLinkedin
            }
        });
        if (result == null || result == "") {
            this.setState({
                loading: false,
                name: "",
                files: null,
                newImageUrl: null,
                hasInstagram: false,
                hasTelegram: false,
                hasTwitter: false,
                hasLinkedin: false,
            });
            return null;
        }
        this.props.selectProject(result);
        toastr.success("با موفقیت انجام شد!");
        this.props.history.push("/panel/Project/" + result.id);
    };
    state = {
        loading: false,
        name: "",
        files: null,
        newImageUrl: null,
        hasInstagram: false,
        hasTelegram: false,
        hasTwitter: false,
        hasLinkedin: false,
    };

    render() {
        if (this.state.loading) return (
            <div className="new-project-popup">
                <div className="p-y-100">
                    <Loading/>
                </div>
            </div>
        );
        return (
            <div className="new-project-popup">
                <div className="p-b-15">
                    <div className="p-b-5">عنوان پروژه</div>
                    <div>
                        <input value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}
                               className="input"/>
                    </div>
                </div>
                <div className="p-b-15">
                    <div className="p-b-5">انتخاب لوگو</div>
                    <div className="black-filepond">
                        <FileUploader
                            name="file"
                            server={{
                                process: {
                                    url: `/ImageUploader/${this.props.projectId == null ? 0 : this.props.projectId}`,
                                    headers: {
                                        Authorization: `Bearer ${this.props.token}`,
                                    },
                                    onload: (par) => {
                                        par = JSON.parse(par);
                                        if (par.imageUrl) {
                                            this.setState({
                                                newImageUrl: par.imageUrl,
                                            });
                                        }
                                    },
                                },
                                revert: (par, load) => {
                                    console.log(par);
                                    load();
                                },
                                remove: (par, load) => {
                                    console.log(par);
                                    this.setState({
                                        newImageUrl: "",
                                    });
                                    load();
                                },
                            }}
                            files={this.state.files}
                            onChange={({files}) => {
                                this.setState({files});
                            }}
                        />
                    </div>
                </div>
                <div className="p-b-15">
                    <div className="p-b-5">شبکه‌های اجتماعی پیش فرض</div>
                    <div className="d-flex flex-wrap">
                        <CheckBox className="p-x-10 p-y-5 f-14" label="اینستاگرام" value={this.state.hasInstagram}
                                  onChange={(val) => this.setState({hasInstagram: val})}/>
                        <CheckBox className="p-x-10 p-y-5 f-14" label="تلگرام" value={this.state.hasTelegram}
                                  onChange={(val) => this.setState({hasTelegram: val})}/>
                        <CheckBox className="p-x-10 p-y-5 f-14" label="توییتر" value={this.state.hasTwitter}
                                  onChange={(val) => this.setState({hasTwitter: val})}/>
                        <CheckBox className="p-x-10 p-y-5 f-14" label="لینکدین" value={this.state.hasLinkedin}
                                  onChange={(val) => this.setState({hasLinkedin: val})}/>
                    </div>
                </div>
                <div className="p-t-15 text-center">
                    <a onClick={() => this.buildNewProject()} className="btn">
                        تایید
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {token: state.auth.token, projectId: state.project.projectId};
};
export default withRouter(connect(mapStateToProps, {selectProject})(AddProject));
