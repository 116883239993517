import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Button from "./../../Elements/Button";
import ColorPicker from "./../../Elements/ColorPicker";
import { connect } from "react-redux";
import FileUploader from "./../../Elements/FileUploader/FileUploader";
import axios from "axios";
import toastr from "toastr";
import { setProject } from "./../../../actions/index";
import Loading from "./../../Elements/Loading";
import TopMenu from "./../../Layout/TopMenu";
import callServer from "../../../axios/callServer";
import { withRouter } from "react-router-dom";

class PageRender extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
    }
    getStartFile = (p) => {
        if (this.props.project.imageUrl != null) {
            return [
                {
                    source: this.props.project.imageUrl,
                    options: {
                        type: "local",
                        metadata: {
                            poster: this.props.project.imageUrl,
                        },
                    },
                },
            ];
        } else {
            return null;
        }
    };

    state = {
        id: this.props.project.id,
        name: this.props.project.name,
        color: this.props.project.color,
        files: this.getStartFile(),
        newImageUrl: null,
    };
    onEditClick = async () => {
        const { data } = await axios.post(
            `/Projects/Edit`,
            {
                id: this.props.project.id,
                name: this.state.name,
                color: this.state.color,
                newImageUrl: this.state.newImageUrl,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.token}`,
                },
            }
        );
        this.props.setProject(data);
        toastr.success("با موفقیت انجام شد!");
        this.setState({ newImageUrl: null });
    };

    onDelete = async () => {
        if (window.confirm("آیا مطمئن هستید؟")) {
            if (window.confirm("با حذف پروژه تمامی فایل ها و مدیا پست های آن نیز حذف خواهد شد ٬ ادامه می دهید؟")) {
                var res = await callServer("get", "/Projects/Delete/" + this.props.project.id, this.props.token);
                if (res != null) {
                    toastr.success("انجام شد!");
                    this.props.history.push(`/panel`);
                }
            }
        }
    };

    render() {
        if (this.props.project.id != null)
            return (
                <div className="main-content black-filepond pos-rel">
                    <div className="m-x-40">
                        <TopMenu hasBackButton={true} projectId={this.props.project.id} />
                        <div className="back-smoke">
                            <div className="row jc-center p-t-30 p-b-100 m-b-100 ai-center p-x-15">
                                <div className="lg-6">
                                    <div className="dir-rtl box">
                                        <h3 className="text-center p-t-10 p-b-20 f-18">ویرایش اطلاعات پروژه</h3>

                                        <div className="row">
                                            <div className="lg-6">
                                                <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                                                    <p>نام پروژه :</p>
                                                    <div className="grow-1 p-r-10">
                                                        <input
                                                            type="text"
                                                            onChange={(e) => this.setState({ name: e.target.value })}
                                                            value={this.state.name}
                                                            className="input"
                                                        />
                                                        {this.validator.message("name", this.state.name, "required|alpha")}
                                                    </div>
                                                </div>
                                                <div className="d-flex ai-center f-14 p-t-5 p-b-5">
                                                    <p>رنگ :</p>
                                                    <div className="p-r-10">
                                                        <ColorPicker
                                                            onChange={(color) => this.setState({ color })}
                                                            value={this.state.color}
                                                        />
                                                        <input
                                                            type="text"
                                                            disabled
                                                            value={this.state.color}
                                                            className="input d-none"
                                                        />
                                                        {this.validator.message("color", this.state.color, "required|alpha")}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="lg-6">
                                                <div className="d-flex ai-center p-r-15 grow-1 f-14 p-t-5 p-b-5">
                                                    <p>عکس :</p>
                                                    <div className="grow-1 p-r-10">
                                                        <FileUploader
                                                            name="file"
                                                            server={{
                                                                process: {
                                                                    url: `/ImageUploader/${this.props.projectId}`,
                                                                    headers: {
                                                                        Authorization: `Bearer ${this.props.token}`,
                                                                    },
                                                                    onload: (par) => {
                                                                        par = JSON.parse(par);
                                                                        if (par.imageUrl) {
                                                                            this.setState({
                                                                                newImageUrl: par.imageUrl,
                                                                            });
                                                                        }
                                                                    },
                                                                },
                                                                revert: (par, load) => {
                                                                    console.log(par);
                                                                    load();
                                                                },
                                                                remove: (par, load) => {
                                                                    console.log(par);
                                                                    this.setState({
                                                                        newImageUrl: "",
                                                                    });
                                                                    load();
                                                                },
                                                            }}
                                                            files={this.state.files}
                                                            onChange={({ files }) => {
                                                                this.setState({ files });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-center p-t-15">
                                            <span onClick={this.onEditClick}>
                                                <Button className="m-x-5">ویرایش </Button>
                                            </span>

                                            <span onClick={this.onDelete}>
                                                <Button className="m-x-5 archieve-btn">حذف پروژه </Button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        else return <Loading />;
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token };
};
export default withRouter(connect(mapStateToProps, { setProject })(PageRender));
