import React from "react";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginGetFile from "filepond-plugin-get-file";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";
import "filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css";
import { translate } from "./FilePondTranslate";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
// import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFilePoster);
registerPlugin(FilePondPluginGetFile);

// Our app
class FileUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // Set initial files, type 'local' means this is a file
            // that has already been uploaded to the server (see docs)

            files: this.props.files,
        };
    }

    handleInit() {
        //console.log("FilePond instance has initialised", this.pond);
    }

    render() {
        return (
            <div className="App">
                <FilePond
                    ref={(ref) => (this.pond = ref)}
                    files={this.props.files}
                    allowMultiple={this.props.allowMultiple}
                    allowReorder={true}
                    maxFiles={this.props.maxFiles == null ? 10 : this.props.maxFiles}
                    server={this.props.server}
                    name={this.props.name}
                    oninit={() => this.handleInit()}
                    onupdatefiles={(fileItems) => {
                        // Set currently active file objects to this.state
                        this.setState({ files: fileItems.map((fileItem) => fileItem.file) });
                        this.props.onChange({ files: fileItems.map((fileItem) => fileItem.file) });
                        console.log(fileItems.forEach((file) => console.log(file)));
                    }}
                    {...translate}
                />
                {this.props.download ? (
                    <div className="d-flex jc-end default-color">
                        <span
                            className="border border-default-color p-5"
                            onClick={() => {
                                console.log("Download ALL");
                            }}
                        >
                            دانلود همه
                        </span>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default FileUploader;
