export const translate = {
    // labelIdle: 'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>'
    labelIdle:
        '<span class="d-inblock dir-rtl">فایل خود را به اینجا بکشید یا آن را <span class="filepond--label-action"> انتخاب </span> کنید.</span>',
    // labelInvalidField: 'Field contains invalid files',
    labelInvalidField: "فایلی با پسوند غیر مجاز انتخاب شده!",
    // labelFileWaitingForSize: 'Waiting for size',
    labelFileWaitingForSize: "محاسبه‌ی اندازه‌ی فایل",
    // labelFileSizeNotAvailable: 'Size not available',
    labelFileSizeNotAvailable: "سایز فایل بیش از حد مجاز",
    // labelFileLoading: 'Loading',
    labelFileLoading: "لطفا صبر کنید",
    // labelFileLoadError: 'Error during load',
    labelFileLoadError: "فایل به درستی لود نشد",
    // labelFileProcessing: 'Uploading',
    labelFileProcessing: "در حال آپلود",
    // labelFileProcessingComplete: 'Upload complete',
    labelFileProcessingComplete: "آپلود کامل شد",
    // labelFileProcessingAborted: 'Upload cancelled',
    labelFileProcessingAborted: "آپلود لغو شد",
    // labelFileProcessingError: 'Error during upload',
    labelFileProcessingError: "خطا در آپلود",
    // labelFileProcessingRevertError: 'Error during revert',
    labelFileProcessingRevertError: "Erro ao reverter o envio",
    // labelFileRemoveError: 'Error during remove',
    labelFileRemoveError: "Erro ao remover o arquivo",
    // labelTapToCancel: 'tap to cancel',
    labelTapToCancel: "clique para cancelar",
    // labelTapToRetry: 'tap to retry',
    labelTapToRetry: "clique para reenviar",
    // labelTapToUndo: 'tap to undo',
    labelTapToUndo: "clique para desfazer",
    // labelButtonRemoveItem: 'Remove',
    labelButtonRemoveItem: "Remover",
    // labelButtonAbortItemLoad: 'Abort',
    labelButtonAbortItemLoad: "Abortar",
    // labelButtonRetryItemLoad: 'Retry',
    labelButtonRetryItemLoad: "Reenviar",
    // labelButtonAbortItemProcessing: 'Cancel',
    labelButtonAbortItemProcessing: "Cancelar",
    // labelButtonUndoItemProcessing: 'Undo',
    labelButtonUndoItemProcessing: "Desfazer",
    // labelButtonRetryItemProcessing: 'Retry',
    labelButtonRetryItemProcessing: "Reenviar",
    // labelButtonProcessItem: 'Upload',
    labelButtonProcessItem: "Enviar",
    // labelMaxFileSizeExceeded: 'File is too large',
    labelMaxFileSizeExceeded: "Arquivo é muito grande",
    // labelMaxFileSize: 'Maximum file size is {filesize}',
    labelMaxFileSize: "O tamanho máximo permitido: {filesize}",
    // labelMaxTotalFileSizeExceeded: 'Maximum total size exceeded',
    labelMaxTotalFileSizeExceeded: "Tamanho total dos arquivos excedido",
    // labelMaxTotalFileSize: 'Maximum total file size is {filesize}',
    labelMaxTotalFileSize: "Tamanho total permitido: {filesize}",
    // labelFileTypeNotAllowed: 'File of invalid type',
    labelFileTypeNotAllowed: "Tipo de arquivo inválido",
    // fileValidateTypeLabelExpectedTypes: 'Expects {allButLastType} or {lastType}',
    fileValidateTypeLabelExpectedTypes: "Tipos de arquivo suportados são {allButLastType} ou {lastType}",
    // imageValidateSizeLabelFormatError: 'Image type not supported',
    imageValidateSizeLabelFormatError: "Tipo de imagem inválida",
    // imageValidateSizeLabelImageSizeTooSmall: 'Image is too small',
    imageValidateSizeLabelImageSizeTooSmall: "Imagem muito pequena",
    // imageValidateSizeLabelImageSizeTooBig: 'Image is too big',
    imageValidateSizeLabelImageSizeTooBig: "Imagem muito grande",
    // imageValidateSizeLabelExpectedMinSize: 'Minimum size is {minWidth} × {minHeight}',
    imageValidateSizeLabelExpectedMinSize: "Tamanho mínimo permitida: {minWidth} × {minHeight}",
    // imageValidateSizeLabelExpectedMaxSize: 'Maximum size is {maxWidth} × {maxHeight}',
    imageValidateSizeLabelExpectedMaxSize: "Tamanho máximo permitido: {maxWidth} × {maxHeight}",
    // imageValidateSizeLabelImageResolutionTooLow: 'Resolution is too low',
    imageValidateSizeLabelImageResolutionTooLow: "Resolução muito baixa",
    // imageValidateSizeLabelImageResolutionTooHigh: 'Resolution is too high',
    imageValidateSizeLabelImageResolutionTooHigh: "Resolução muito alta",
    // imageValidateSizeLabelExpectedMinResolution: 'Minimum resolution is {minResolution}',
    imageValidateSizeLabelExpectedMinResolution: "Resolução mínima permitida: {minResolution}",
    // imageValidateSizeLabelExpectedMaxResolution: 'Maximum resolution is {maxResolution}'
    imageValidateSizeLabelExpectedMaxResolution: "Resolução máxima permitida: {maxResolution}",
};
