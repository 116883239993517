import React from "react";
import NewSocialMedia from "../PageParts/ProjectSocialMedia/NewSocialMedia";
import Loading from "./../Elements/Loading";
import SocialMediaItem from "./../PageParts/ProjectSocialMedia/SocialMediaItem";
import axios from "axios";
import { connect } from "react-redux";
import TopMenu from "./../Layout/TopMenu";

class ProjectSocialMedia extends React.Component {
    state = { socialMedias: null };
    async componentDidMount() {
        if (this.props.match.params.id == null) this.props.match.params.id = this.props.projectId;
        const { data } = await axios.get(`/SocialNetworks/${this.props.match.params.id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.props.token}`,
            },
        });
        console.log(data);

        this.setState({ socialMedias: data });
    }
    showSocialMedias = () => {
        return this.state.socialMedias.map((s) => {
            return (
                <SocialMediaItem
                    deleteSocialMedia={(id) => this.setState({ socialMedias: this.state.socialMedias.filter((s) => s.id !== id) })}
                    key={s.id}
                    socialMedia={s}
                    projectId={this.props.match.params.id}
                />
            );
        });
    };
    render() {
        if (this.state.socialMedias != null && this.state.socialMedias !== "") {
            return (
                <div className="main-content black-filepond pos-rel">
                    <div className="m-x-40">
                        <TopMenu hasBackButton={true} projectId={this.props.match.params.id} />
                        <div className="back-smoke">
                            <div className="row jc-center p-t-30 p-b-100 m-b-100 ai-center p-x-15">
                                <div className="lg-10 box main-box-header pos-rel">
                                    <h4 className="f-22 p-y-15 text-center">شبکه های اجتماعی</h4>
                                    <div className="row p-t-10 ai-center jc-center">
                                        {this.showSocialMedias()}
                                        <NewSocialMedia
                                            projectId={this.props.match.params.id}
                                            onAdd={(data) => {
                                                this.setState({ socialMedias: [...this.state.socialMedias, data] });
                                            }}
                                            socialMedia={{}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Loading />;
        }
    }
}
const mapStateToProps = (state) => {
    return { token: state.auth.token };
};
export default connect(mapStateToProps)(ProjectSocialMedia);
