import React from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

class ColorPicker extends React.Component {
    state = {
        displayColorPicker: false,
        color: this.props.value,
    };
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    handleChange = (color) => {
        this.setState({ color: color.hex });
        this.props.onChange(color.hex);
    };

    render() {
        const styles = reactCSS({
            default: {
                color: {
                    width: this.props.fullWidth ? "100%" : "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: this.state.color,
                },
                swatch: {
                    padding: "5px",
                    background: "#fff",
                    borderRadius: "5px",
                    boxShadow: "0 0 0 1px #4c7deb",
                    display: "block",
                    cursor: "pointer",
                },
                popover: {
                    position: "absolute",
                    zIndex: "2",
                },
                cover: {
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                },
            },
        });

        return (
            <div>
                <div style={styles.swatch} onClick={this.handleClick}>
                    <div style={styles.color} />
                </div>
                {this.state.displayColorPicker ? (
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose} />
                        <SketchPicker color={this.state.color == null ? "#00000" : this.state.color} onChange={this.handleChange} />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default ColorPicker;
