import React, { Component } from "react";

export default class NoDataFound extends Component {
    render() {
        return (
            <div className="back-smoke p-y-50 position-relative row ai-center jc-center">
                <div>{this.props.text ?? "داده ای در دسترس نیست!"}</div>
            </div>
        );
    }
}
