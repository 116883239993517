import React, { Component } from "react";
import NextPost from "./Dashboard/NextPost";
import PostsSummary from "./Dashboard/PostsSummary";
import ProjectsSummary from "./Dashboard/ProjectsSummary";
import RecentIdeas from "./Dashboard/RecentIdeas";
import { Link } from "react-router-dom";
import AddProject from "./Dashboard/AddProject";

class Dashboard extends Component {
    state = { showNewProject: false };

    render() {
        return (
            <div className="p-t-20">
                <div className="add-new m-b-10">
                    <a onClick={() => this.setState({ showNewProject: !this.state.showNewProject })} className="m-l-10">
                        <i className="zmdi zmdi-collection-add m-l-5"></i>پروژه جدید
                    </a>
                    <Link to="/panel/post/new" className="m-l-10">
                        <i className="zmdi zmdi-file-plus m-l-5"></i>پست جدید
                    </Link>
                    <Link to="/panel/Ideas">
                        <i className="zmdi zmdi-file-plus m-l-5"></i>ایده ها
                    </Link>
                </div>
                <div className="row ">
                    <div className="lg-8">
                        <div className="row">
                            <div className="lg-6 m-t-10">
                                <ProjectsSummary />
                            </div>
                            <div className="lg-6 m-t-10">
                                <PostsSummary />
                            </div>
                        </div>
                        <div className="row">
                            <div className="lg-6 m-t-10">
                                <RecentIdeas />
                            </div>
                            {/* <div className="lg-6 m-t-10">
                                <div className="projects">
                                    <h2>آرشیو</h2>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="lg-4">
                        <NextPost />
                    </div>
                </div>
                {this.state.showNewProject ? (
                    <div>
                        <div className="cover cover-bg" onClick={() => this.setState({ showNewProject: !this.state.showNewProject })}></div>
                        <AddProject />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default Dashboard;
