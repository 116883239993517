import React, { Component } from "react";
import TaskPopup from "./TaskPopup";
import toastr from "toastr";
import UserImage from "./../Common/UserImage";

class HorizontalTaskManagement extends Component {
    state = { show: false };
    showCurrentUsers = () => {
        return this.props.currentUsers.map((u) => <UserImage user={u} />);
    };

    render() {
        if (!this.props.isProjectSelected)
            return (
                <div className="pos-rel">
                    <div className="d-flex ai-center jc-between">
                        <div className="full-width-link pointer" onClick={() => this.setState({ show: true })}></div>
                        <div className={this.props.titleClassName ?? ""}>{this.props.title}</div>
                        <span className="plus-btn">
                            <i className="zmdi zmdi-plus text-white"></i>
                        </span>
                    </div>

                    <div className="current-users-container">{this.showCurrentUsers()}</div>

                    {this.state.show ? (
                        <div>
                            <div className="auto-close bg-light-black z-index-1" onClick={() => this.setState({ show: false })}></div>
                            <div className="task-popup">
                                <div className="f-16 text-center red">ابتدا یک پروژه انتخاب کنید</div>
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        return (
            <div className="pos-rel">
                <div className="d-flex ai-center jc-between">
                    <div className="full-width-link pointer" onClick={() => this.setState({ show: true })}></div>
                    <div className={this.props.titleClassName ?? ""}>{this.props.title}</div>
                    <span className="plus-btn">
                        <i className="zmdi zmdi-plus text-white"></i>
                    </span>
                </div>

                <div className="current-users-container">{this.showCurrentUsers()}</div>

                {this.state.show ? (
                    <TaskPopup
                        title={this.props.title}
                        users={this.props.users}
                        currentUsers={this.props.currentUsers}
                        currentUsersChange={this.props.currentUsersChange}
                        hide={() => this.setState({ show: false })}
                        postId={this.props.postId}
                        type={this.props.type}
                    />
                ) : null}
            </div>
        );
    }
}

export default HorizontalTaskManagement;
