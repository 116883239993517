import { combineReducers } from "redux";
import authReducer from "./authReducer";
import notifications from "./notifications";
import projectReducer from "./projectReducer";

export default combineReducers({
    auth: authReducer,
    project: projectReducer,
    notification: notifications,
});
