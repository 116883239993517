import React, {Component} from "react";
import callServer from "./../../../../axios/callServer";
import {connect} from "react-redux";
import Loading from "../../../Elements/Loading";
import {Link} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

class ProjectsSummary extends Component {
    state = {isReady: false, Projects: []};

    async componentDidMount() {
        const result = await callServer("get", "/Projects/Summary", this.props.token);
        if (result == null) return;
        this.setState({
            isReady: true,
            projects: result,
        });
    }

    render() {
        return (
            <div className="projects">
                <h2>پروژه‌ها</h2>
                <div>
                    <PerfectScrollbar style={{height: 265}}>
                        <div className="projects-repeater" style={{marginTop: 0}}>
                            {this.state.isReady ? (
                                this.state.projects?.map((p) => {
                                    return (
                                        <Link to={`/panel/project/${p.project?.id}`}>
                                            <div className="each-project">
                                                <div className="d-flex">
                                                    <img src={p.project?.image?.url} alt=""/>
                                                    <span className="p-r-10">{p.project?.name}</span>
                                                </div>
                                                <span>{p.postsCount} پست</span>
                                            </div>
                                        </Link>
                                    );
                                })
                            ) : (
                                <div className="pos-rel p-t-100">
                                    <Loading/>
                                </div>
                            )}
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {token: state.auth.token, projectId: state.project.projectId};
};
export default connect(mapStateToProps)(ProjectsSummary);
