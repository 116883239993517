import React, { Component } from "react";
import Loading from "../../Elements/Loading";
import * as moment from "jalali-moment";
import { Link } from "react-router-dom";
import ReadMore from "../../Elements/ReadMore";
import Slider from "./../../Elements/Slider";
import ToPersianDate from "./../../Elements/ToPersianDate";

class PostItem extends Component {
    handleTags = (post) => {
        let tags = "";
        post.postTags?.map((pTag) => {
            tags += pTag.tag?.title + " ";
        });
        return tags;
    };
    handleTagsArr = (post) => {
        return post.postTags?.map((pTag) => {
            return <span className="dir-rtl p-l-10 d-inblock w-normal">{pTag.tag?.title}</span>;
        });
    };
    renderPostMedia(post) {
        post.media = post.mediaCollections;
        if (post.media == null) return;
        else if (post.media.length > 0) {
            return post.media.map((media) => {
                const arr = media.url?.split(".");
                if (arr[arr.length - 1] == "mp4") {
                    return (
                        <div key={media.id}>
                            <video className="w-100" height="240" controls>
                                <source src={media.url} type="video/mp4" />
                            </video>
                        </div>
                    );
                } else {
                    return (
                        <div key={media.id}>
                            <img src={media.url} alt="" />
                        </div>
                    );
                }
            });
        }
    }

    renderPostItem(post) {
        return (
            <div className={`d-flex flex-column post-preview pos-rel ${this.props.className}`}>
                <Link className="full-width-link" to={`/panel/post/${post.id}`}></Link>
                <div className="d-flex jc-between">
                    <div>
                        <span className="default-color f-14">{post.status?.name ?? "پیش نویس"}</span>
                        <h2 className="p-y-5 m-b-0">{post.name}</h2>
                    </div>
                    <div className="post-item-image">
                        <img
                            alt=""
                            className="mini-image"
                            src={post.socialNetwork?.image?.url ?? (post.socialNetwork == null ? "/Images/no-social.png" : "")}
                        />
                    </div>
                </div>
                <div className="bring-to-front">
                    <Slider>{this.renderPostMedia(post)}</Slider>
                </div>
                <div className="text-justify p-t-10 ">
                    <ReadMore size="300">{post.description + " " + this.handleTags(post)}</ReadMore>
                </div>
                <div className="text-left">{new ToPersianDate().toDate(post.dueDate, true).locale("fa").calendar()}</div>
            </div>
        );
    }

    renderPostItemPagePreview(post) {
        return (
            <div className={`d-flex flex-column post-preview pos-rel ${this.props.className}`}>
                <div className="bring-to-front">
                    <Slider>{this.renderPostMedia(post)}</Slider>
                </div>
                <div className="text-justify p-t-10 pre-line">
                    {post.description}
                    <br />
                    {this.handleTagsArr(post)}
                </div>
            </div>
        );
    }

    render() {
        let post;
        if (this.props.post.post) {
            post = this.props.post.post;
        } else {
            post = this.props.post;
        }
        if (post != null) {
            if (this.props.isPagePreview) {
                return this.renderPostItemPagePreview(post);
            }
            return this.renderPostItem(post);
        }
        return <Loading />;
    }
}

export default PostItem;
